// import __authors from "./jsons/__users.json";
import { AuthorType } from "./types";
import avatar1 from "images/day_01.png";
import avatar2 from  "images/day_02.png";
import avatar3 from  "images/day_03.png";
import avatar4 from  "images/day_04.png";
import avatar5 from  "images/day_05.png";
import avatar6 from  "images/day_06.png";
import avatar7 from "images/avatars/Image-7.png";
import avatar8 from "images/avatars/Image-8.png";
import avatar9 from "images/avatars/Image-9.png";
import avatar10 from "images/avatars/Image-10.png";
import avatar11 from "images/avatars/Image-11.png";
import avatar12 from "images/avatars/Image-12.png";
import avatar13 from "images/avatars/Image-13.png";
import avatar14 from "images/avatars/Image-14.png";
import avatar15 from "images/avatars/Image-15.png";
import avatar16 from "images/avatars/Image-16.png";
import avatar17 from "images/avatars/Image-17.png";
import avatar18 from "images/avatars/Image-18.png";
import avatar19 from "images/avatars/Image-19.png";
import avatar20 from "images/avatars/Image-20.png";


const authors=[
  {
    "id": 1,
    "firstName": "Alric",
    "lastName": "Truelock",
    "displayName": "DAY 1",
    "email": "atruelock0@skype.com",
    "gender": "Bigender",
    "avatar": "https://uifaces.co/our-content/donated/1H_7AxP0.jpg",
    "bgImage":"images/day_01.png",
    "count": 40,
    "href": "/author",
    "desc": "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    "jobName": "Author Job"
  },
  {
    "id": 2,
    "firstName": "Chariot",
    "lastName": "Birrell",
    "displayName":"DAY 2",
    "email": "cbirrell1@google.com.hk",
    "gender": "Genderfluid",
    "avatar": "https://uifaces.co/our-content/donated/gPZwCbdS.jpg",
   
    "count": 113,
    "href": "/author",
    "desc": "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    "jobName": "Author Job",
    "bgImage": "images/day_02.png"
  },
  {
    "id": 3,
    "firstName": "Nathanil",
    "lastName": "Foulcher",
    "displayName": "DAY 3",
    "email": "nfoulcher2@google.com.br",
    "gender": "Bigender",
    "avatar": "https://uifaces.co/our-content/donated/3799Ffxy.jpeg",
    "count": 43,
    "href": "/author",
    "desc": "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    "jobName": "Author Job",
    "bgImage":"images/day_03.png"
  },
  {
    "id": 4,
    "firstName": "Agnes",
    "lastName": "Falconar",
    "displayName": "DAY 4",
    "email": "afalconar3@google.ru",
    "gender": "Non-binary",
    "avatar": "https://uifaces.co/our-content/donated/KtCFjlD4.jpg",
    "count": 36,
    "href": "/author",
    "desc": "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    "jobName": "Author Job",
    "bgImage": "images/day_04.png"
  },
  {
    "id": 5,
    "firstName": "Vita",
    "lastName": "Tousy",
    "displayName": "DAY 5",
    "email": "vtousy4@elpais.com",
    "gender": "Male",
    "avatar": "https://uifaces.co/our-content/donated/xZ4wg2Xj.jpg",
    "count": 38,
    "href": "/author",
    "desc": "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    "jobName": "Author Job",
    "bgImage": "images/day_05.png"
  },
  {
    "id": 6,
    "firstName": "Donna",
    "lastName": "Friar",
    "displayName": "DAY 6",
    "email": "dfriar5@telegraph.co.uk",
    "gender": "Agender",
    "avatar": "https://uifaces.co/our-content/donated/bUkmHPKs.jpg",
    "count": 31,
    "href": "/author",
    "desc": "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    "jobName": "Author Job",
    "bgImage":"images/day_06.png"
  },]

const imgs = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
  avatar16,
  avatar17,
  avatar18,
  avatar19,
  avatar20,
];

const DEMO_AUTHORS: AuthorType[] = authors.map((item, index) => ({
  ...item,
  avatar: imgs[index] || item.avatar,
}));

export { DEMO_AUTHORS };
