import ArrivingCities from "constants/ArrivingToCities";
import moment from "moment";
import { createContext, useContext, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams as useReactSearchParams,
} from "react-router-dom";

import {
  addDate,
  addGuests,
  addTravelingFrom,
  addTravelingTo,
  resetSearch,
} from "store/actions/search";
import {
  Dates,
  Guests,
  TravelingFrom,
  TravelingTo,
} from "store/reducers/search";

type SearchParams = {
  travelingFrom: TravelingFrom;
  travelingTo: TravelingTo;
  dates: Dates;
  guests: Guests;
};

type SearchParamsContextType = {
  getStringParams: () => string;
  searchParams: URLSearchParams;
  data: () => SearchParams | null;
};

const SearchParamsContext = createContext<SearchParamsContextType | undefined>(
  undefined
);

type SearchParamsProviderProps = {
  children: ReactNode;
};

const isNullOrEmpty = (value: string | null | undefined): boolean =>
  value === null || value === undefined || value === "";

const parseSearchParams = (param: string | null): any => {
  return isNullOrEmpty(param) ? null : JSON.parse(param!);
};

export const SearchParamsProvider: React.FC<SearchParamsProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const search = useSelector((reducer: any) => reducer.search);
  const [searchParams] = useReactSearchParams();

  const checkSearchParams = (): SearchParams | null => {
    const from = searchParams.get("travelingFrom");
    const to = searchParams.get("travelingTo");
    const dates = searchParams.get("dates");
    const guests = searchParams.get("guests");
    if (
      isNullOrEmpty(from) ||
      isNullOrEmpty(to) ||
      isNullOrEmpty(dates) ||
      isNullOrEmpty(guests)
    ) {
      return null;
    }
    return {
      travelingFrom: parseSearchParams(from),
      travelingTo: parseSearchParams(to),
      dates: parseSearchParams(dates),
      guests: parseSearchParams(guests),
    } as SearchParams;
  };

  const ignoringRoutes: string[] = [
    "/pay-done",
    "/about",
    "/accreditations",
    "/faq",
    "/contact",
    "/privacy-policy",
    "/terms-of-use",
  ];

  useEffect(() => {
    const searchParamsFromUrl = checkSearchParams();
    const pathName = location.pathname;
    if (
      !ignoringRoutes.some((route) => pathName.startsWith(route)) &&
      !location.pathname.includes("blog")
    ) {
      // if (!searchParamsFromUrl) {
      //   dispatch(resetSearch());
      //   navigate("/");
      // }

      const handleHistoryChange = () => {
        const updatedSearchParams = checkSearchParams();
        // if (!updatedSearchParams) {
        //     dispatch(resetSearch())
        //     navigate("/");
        // }
        if (!updatedSearchParams) {
          dispatch(resetSearch());
        } else {
          dispatch(
            addTravelingFrom(updatedSearchParams!.travelingFrom || null)
          );
          dispatch(addTravelingTo(updatedSearchParams!.travelingTo || null));
          dispatch(
            addDate({
              startDate: moment(updatedSearchParams!.dates.startDate),
              endDate: moment(searchParamsFromUrl!.dates.endDate),
            })
          );
          dispatch(addGuests(updatedSearchParams!.guests));
        }
      };
      window.addEventListener("popstate", handleHistoryChange);

      return () => {
        window.removeEventListener("popstate", handleHistoryChange);
      };
    }
  }, [dispatch, location.pathname]);

  const getStringParams = (): string => {
    return createSearchParams({
      travelingFrom: JSON.stringify(search.travelingFrom),
      travelingTo: JSON.stringify("Larnaca"),
      dates: JSON.stringify(search.dates),
      guests: JSON.stringify(search.guests),
    }).toString();
  };

  return (
    <SearchParamsContext.Provider
      value={{ getStringParams, searchParams, data: checkSearchParams }}
    >
      {children}
    </SearchParamsContext.Provider>
  );
};

export const useCustomSearchParams = (): SearchParamsContextType => {
  const context = useContext(SearchParamsContext);
  if (!context) {
    throw new Error(
      "useCustomSearchParams must be used within a SearchParamsProvider"
    );
  }
  return context;
};
