import { FC, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { addDate } from "store/actions/search";
import useNcId from "hooks/useNcId";
import { Dates } from "store/reducers/search";

interface TravelDatesInputProps {
  className?: string;
  onBlur?: () => void;
  onDatesChange?: (dates: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  error?: string;
}

const TravelDatesInput: FC<TravelDatesInputProps> = ({
  className = "",
  onBlur,
  onDatesChange,
  error,
}) => {
  const dispatch = useDispatch();
  const travelingDate: Dates = useSelector((state: any) => state.search.dates);

  const [dates, setDates] = useState<Dates>({
    startDate: moment("10/31/2024"),
    endDate: moment("11/05/2024"),
  });
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const startDateId = useNcId();
  const endDateId = useNcId();

  const minDate = moment("10/25/2024");
  const maxDate = moment("11/10/2024");

  const isOutsideRange = (day: Moment) => {
    return day.isBefore(minDate, "day") || day.isAfter(maxDate, "day");
  };

  const handleValueChange = (newValue: Dates) => {
    // Ensure that the endDate cannot be before startDate
    if (newValue.startDate && newValue.endDate) {
      if (newValue.startDate.isAfter(newValue.endDate)) {
        newValue.endDate = newValue.startDate;
      }
    }
    setDates(newValue);
    onDatesChange && onDatesChange(newValue);
    dispatch(addDate(newValue));
  };

  function handleDateFocusChange(
    nextFocusInput: FocusedInputShape | null
  ): void {
    onBlur && onBlur();
    setFocusedInput(nextFocusInput);
  }

  useEffect(() => {
    handleValueChange(dates);
  }, [dates]);

  return (
    <div className={`h-24 relative ${className}`}>
      <Popover className="flex relative h-full">
        {({ open }) => (
          <>
            <div
              className={`flex-1 flex items-center focus:outline-none cursor-pointer   ${
                open ? "nc-hero-field-focused" : ""
              }`}
            >
              {" "}
              <Popover.Button className="w-full h-full">
                <form className="flex h-full flex-row divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2 w-full">
                  <div className="w-1/2 p-4 flex items-center space-x-3 flex relative h-full">
                    <div className="text-neutral-300 dark:text-neutral-400">
                      <div className="text-2xl">
                        <i className="la la-calendar"></i>
                      </div>
                    </div>
                    <div className="flex flex-col items-start">
                      <span className="block xl:text-lg font-semibold">
                        {dates.startDate
                          ? dates.startDate.format("DD MMM")
                          : " Start Date"}
                      </span>
                      <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                        Check In
                      </span>
                    </div>
                  </div>
                  <div className="w-1/2 p-4 items-center space-x-3 flex relative h-full">
                    <div className="text-neutral-300 dark:text-neutral-400">
                      <div className="text-2xl">
                        <i className="la la-calendar"></i>
                      </div>
                    </div>
                    <div className="flex flex-col items-start">
                      <span className="block xl:text-lg font-semibold">
                        {dates.endDate
                          ? dates.endDate.format("DD MMM")
                          : " End Date"}
                      </span>
                      <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                        Check-out
                      </span>
                    </div>
                  </div>
                </form>
              </Popover.Button>
            </div>
            <Transition
              as="div"
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 -translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-1"
            >
              {open && (
                <Popover.Panel
                  style={{ height: 0 }}
                  className="absolute left-32 z-10 w-full max-w-lg bg-white dark:bg-neutral-800 -top-full mb-3  rounded-3xl shadow-xl"
                >
                  <div className="w-full ">
                    <DateRangePicker
                      required={true}
                      disabled={false}
                      startDate={dates.startDate}
                      endDate={dates.endDate}
                      onDatesChange={handleValueChange}
                      onFocusChange={handleDateFocusChange}
                      focusedInput={focusedInput || "startDate"}
                      startDateId={startDateId}
                      endDateId={endDateId}
                      daySize={33}
                      verticalHeight={100}
                      orientation={"horizontal"}
                      showClearDates
                      noBorder
                      hideKeyboardShortcutsPanel
                      anchorDirection={"left"}
                      customArrowIcon={<div />}
                      reopenPickerOnClearDates
                      isOutsideRange={isOutsideRange} // Disable dates outside the range
                    />
                  </div>
                </Popover.Panel>
              )}
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default TravelDatesInput;
