import { useState, useEffect } from "react";

const useScrollPosition = (targetRef: any) => {
  const [isSearchFormInView, setIsSearchFormInView] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        setIsSearchFormInView(rect.top >= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [targetRef]);

  return isSearchFormInView;
};

export default useScrollPosition;
