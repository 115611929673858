import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import { FC } from "react";
import { useEffect } from "react";
import clientSayMain from "images/clientSayMain.png";
import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";
import clientSay6 from "images/clientSay6.png";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import useNcId from "hooks/useNcId";

export interface SectionClientSayProps {
  className?: string;
  uniqueClassName: string;
}

const DEMO_DATA = [
  {
    id: 1,
    clientName: "",
    clientAddress: "",
    content:
      'The 1st European Art and Culture Disability Festival DIS will take place from November 1st to 5th, 2024, in Larnaca, a city renowned for its rich cultural heritage and vibrant community. The festival is co-organized by the "Neofytos" Cultural Organization, the EASPD, and Larnaca Cultural Capital 2030, with the support of the Municipality of Larnaca, the Ministry of Education, Youth, and Sports, and the Deputy Ministry of Culture of Cyprus.',
  },
  {
    id: 2,
    clientName: "Lennie Swiffan",
    clientAddress: "London",
    content:
      "The festival aims to create a space for meeting and exchanging ideas, promoting the inclusion of people with disabilities and the value of diversity. Through a variety of events, such as exhibitions, workshops, theatrical performances, academic presentations, and the showcasing of significant works for the disability community, the festival seeks to inspire and encourage cultural dialogue and cooperation among different communities.",
  },
  {
    id: 3,
    clientName: "Berta Emili",
    clientAddress: "Tokyo",
    content:
      'The festival takes its name from the annual art exhibition DIS, organized by "Neofytos" since its inception. This year, the exhibition takes on a pan-European character, inviting participation from other countries and offering a unique platform for artists and creators with disabilities to showcase their work.',
  },
  {
    id: 4,
    clientName: "Berta Emili",
    clientAddress: "Tokyo",
    content:
      'Under the motto "Together, We Are Better!", the festival promotes a message of unity and collaboration, enhancing our shared understanding and appreciation of diversity. The festival is not just a celebration of art and culture but also a powerful statement in favor of social inclusion and cultural diversity. Larnaca will be transformed into a vibrant hub of creativity, where art serves as a bridge connecting people and ideas from across Europe.',
  },
];

const SectionClientSay: FC<SectionClientSayProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
          autoplay: 10000,
        }).mount();
      }, 10);
    }
  }, []);

  const renderBg = () => {
    return (
      <div className="hidden md:block">
        <img
          draggable="false"
          className="absolute top-9 -left-20"
          src={clientSay1}
          alt=""
        />
        <img
          draggable="false"
          className="absolute bottom-[100px] right-full mr-40"
          src={clientSay2}
          alt=""
        />
        <img
          draggable="false"
          className="absolute top-full left-[140px]"
          src={clientSay3}
          alt=""
        />
        <img
          draggable="false"
          className="absolute -bottom-10 right-[140px]"
          src={clientSay4}
          alt=""
        />
        <img
          draggable="false"
          className="absolute left-full ml-32 bottom-[80px]"
          src={clientSay5}
          alt=""
        />
        <img
          draggable="false"
          className="absolute -right-10 top-10 "
          src={clientSay6}
          alt=""
        />
      </div>
    );
  };

  return (
    <div
      className={` nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <h2 className={`text-5xl font-semibold text-center mb-6`}>
        Together, We are Better{" "}
      </h2>{" "}
      <div className="relative md:mb-16 max-w-2xl mx-auto">
        {renderBg()}
        <img draggable="false" className="mx-auto" src={clientSayMain} alt="" />
        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          {/* <img
            draggable="false"
            className="opacity-50 md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-1"
            src={quotationImg}
            alt=""
          />
          <img
            draggable="false"
            className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
            src={quotationImg2}
            alt=""
          /> */}
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {DEMO_DATA.map((item) => (
                <li
                  key={item.id}
                  className="glide__slide flex flex-col items-center text-center"
                >
                  <span className="block text-2xl">{item.content}</span>
                  {/* <span className="block mt-8 text-2xl font-semibold">
                    {item.clientName}
                  </span> */}
                  {/* <div className="flex items-center space-x-2 text-lg mt-2 text-neutral-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span>{item.clientAddress}</span>
                  </div> */}
                </li>
              ))}
            </ul>
          </div>
          <div
            className="mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            {DEMO_DATA.map((item, index) => (
              <button
                key={item.id}
                className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionClientSay;
