import { FC, useEffect, useRef, useState } from "react";
import { useCustomSearchParams } from "context/SearchParamsContext";
import {
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import stripeSvg from "images/Stripe_(company)-Powered-by-Stripe-Outline-Logo.wine.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { euro } from "utils/currency";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postTripSubmit } from "services/api";
import ValidationError from "images/OT_checkout_problem_white.png";
import ValidationSuccess from "images/OT_checkout_no-problem_white.png";
import {
  HowToGetThereCartItem,
  HowToMoveAroundCartItem,
  WhatToDoCartItem,
  WhatsExtraCartItem,
  WhereToEatCartItem,
  WhereToStayCartItem,
} from "store/reducers/cart";

import {
  createOrder,
  createPaymentIntent,
  getStripeConfig,
} from "services/payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { addOrder } from "store/actions/cart";
import { LocalStorageAdapter } from "LocalStorage/adapter/LocalStorageAdapter";
import { REACT_APP_SITE_KEY } from "config/environment";
import React from "react";

import * as z from "zod";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { COUNTRIES } from "components/ShoppingCart/Form/constant";
import { Tab } from "@headlessui/react";
import { formatDate } from "helpers/methods";
import Seo from "components/Seo/Seo";
import Validation from "components/Validation/Validation";

type AirlineType = {
  code: string;
  name: string;
  logo: string;
  duration: string;
  departureFrom: string;
  departureTime: string;
  arrivalTo: string;
  arrivalTime: string;
};

type HowToGetThereItem = {
  id: string;
  price: number;
  class: string;
  checkedBuggage: string;
  cabinLuggage: string;
  cancelationPolicy: string;
  destinationAirline: AirlineType;
  returnAirline: AirlineType;
  searchGuid: string;
  flightClass: string;
};

type WhereToStayItem = {
  name: string;
  inputs: {
    meals: string;
    cancelation: string;
    guests: number;
    single_count: number;
    double_count: number;
    is_accessible: boolean;
  };
};

type WhatToDoItem = {
  name: string;
  inputs: {
    timeslot: string;
    date: string;
    guests: number;
  };
};

type WhereToEatItem = {
  name: string;
  inputs: {
    timeslot: string;
    date: string;
    seating: string;
    guests: number;
  };
};

type HowToMoveAroundItem = {
  service: string;
  selectedOffers: {
    date?: string | "FIRST_DAY" | "LAST_DAY";
    price: number;
    type: string;
    quantity: number;
  }[];
};
type WhatsExtraItem = {
  service: string;
  selectedOffers: {
    date?: string | "FIRST_DAY" | "LAST_DAY";
    price: number;
    type: string;
    quantity: number;
  }[];
};

export type Customer = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  email: string;
  phoneNumber: string;
  travelDocument: {
    type: string;
    number: string;
    issueCountry: string;
    dateOfIssue: string;
    expirationDate: string;
  };
  mobility: {
    aid: string;
    type: string;
    percentage: number;
    disabilityCard: string;
  };
  allergies: string;
};

export type Order = {
  _id?: string;
  orderId?: string;
  totalPrice: number;
  totalGuests: number;
  dates: string;
  customers: Customer[];
  flight: HowToGetThereItem | null;
  hotel?: WhereToStayItem[];
  dis_hotel: WhereToStayItem[];
  activity: WhatToDoItem[];
  restaurant: WhereToEatItem[];
  transportation: HowToMoveAroundItem[];
  extra: WhatsExtraItem[];
  paymentIntent: string | null;
  clientSecret: string;
  status: "FAILURE" | "PENDING" | "ON_HOLD";
  createdAt?: Date;
};

export interface CheckOutPageProps {
  className?: string;
}
const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);
const onlyLettersRegex = new RegExp(/^[a-zA-Z\s]{2,}$/);
const countryCodes = COUNTRIES.map((cc) => cc.mobilePrefixCode) as [
  string,
  ...string[]
];
const countries = COUNTRIES.map((cc) => cc.name) as [string, ...string[]];
const genders = ["male", "female", "X - undefined"] as [string, ...string[]];
const typeOfMobilityAid = [
  "no use of a mobility aid",
  "crutches",
  "foldable manual wheelchair",
  "foldable power wheelchair",
  "manual wheelchair",
  "manual wheelchair power assist device",
  "power wheelchair",
  "scooter",
  "walker",
  "walking aid",
] as [string, ...string[]];

const mobility: { name: string; description: string }[] = [
  { name: "NONE", description: "" },
  { name: "WCHR", description: "can climb steps and can walk in cabin" },
  { name: "WCHS", description: "unable to climb steps, can walk in cabin" },
  { name: "WCHC", description: "unable to climb steps or walk in cabin" },
];
const mobilityArray = mobility.map((mob) => mob.name) as [string, ...string[]];
const yesOrNo = ["YES", "NO"] as const;
const travelDocuments = ["national id", "passport"] as const;
const customerSchema = z.object({
  gender: z.enum(genders, {
    errorMap: (issue, _ctx) => {
      const message = "Gender is required";
      switch (issue.code) {
        case "invalid_enum_value":
          return { message };
        default:
          return { message };
      }
    },
  }),
  firstName: z
    .string()
    .min(2, { message: "First name is required" })
    .regex(onlyLettersRegex, "First name must be only letters"),
  lastName: z
    .string()
    .min(2, { message: "Last name is required" })
    .regex(onlyLettersRegex, "Last name must be only letters"),
  dateOfBirth: z.coerce
    .date({})
    .max(new Date(), { message: "Date of birth must be at the past" }),
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Invalid email" }),
  phoneNumberPrefix: z.enum(countryCodes, {
    errorMap: (issue, _ctx) => {
      const message = "Prefix number is required";
      switch (issue.code) {
        case "invalid_enum_value":
          return { message };
        default:
          return { message };
      }
    },
  }),
  phoneNumber: z
    .string()
    .min(1, { message: "Phone number is required" })
    .regex(phoneRegex, "Phone number must be only numbers")
    .min(10)
    .max(20),
  travelDocument: z.enum(travelDocuments, {
    errorMap: (issue, _ctx) => {
      const message = "Travel document is required";
      switch (issue.code) {
        case "invalid_enum_value":
          return { message };
        default:
          return { message };
      }
    },
  }),
  countryOfIssue: z.enum(countries, {
    errorMap: (issue, _ctx) => {
      const message = "Country of issue is required";
      switch (issue.code) {
        case "invalid_enum_value":
          return { message };
        default:
          return { message };
      }
    },
  }),
  travelDocumentNumber: z
    .string()
    .min(1, { message: "Travel document number is required" }),
  travelDocumentDateOfIssue: z.coerce
    .date()
    .max(new Date(), { message: "No correct date" }),
  travelDocumentExpirationDate: z.coerce
    .date()
    .min(new Date(), { message: "No correct date" }),
  typeOfMobilityAid: z.enum(typeOfMobilityAid, {
    errorMap: (issue, _ctx) => {
      const message = "Type of mobility aid is required";
      switch (issue.code) {
        case "invalid_enum_value":
          return { message };
        default:
          return { message };
      }
    },
  }),
  mobility: z
    .enum(mobilityArray, {
      errorMap: (issue, _ctx) => {
        const message = "Mobility is required";
        switch (issue.code) {
          case "invalid_enum_value":
            return { message };
          default:
            return { message };
        }
      },
    })
    .optional(),
  disabilityPercentage: z.coerce
    .number()
    .int()
    .min(0)
    .gte(0, { message: "Disability percentage must be bigger than 0" })
    .lte(100, { message: "Disability percentage must be less than 100" }),
  disabilityCard: z.enum(yesOrNo, {
    errorMap: (issue, _ctx) => {
      const message = "Disability card is required";
      switch (issue.code) {
        case "invalid_enum_value":
          return { message };
        default:
          return { message };
      }
    },
  }),
  allergies: z.string().optional(),
});

const arraySchema = z.object({
  customers: z.array(customerSchema),
});

type FormType = z.infer<typeof arraySchema>;

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const href = window.location.href;
  const dispatch = useDispatch();
  const { getStringParams } = useCustomSearchParams();
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isValid },
    setError,
    clearErrors,
  } = useForm<FormType>({
    resolver: zodResolver(arraySchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "customers",
  });

  const [stripePromise, setStripePromise] = useState<any | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isFormFilled, setIsFormFilled] = useState<boolean>(false);
  const [siteRegulation, setSiteRegulation] = useState<{
    privacyPolicy: boolean;
    termsOfUse: boolean;
    termsAndConditions: boolean;
  }>({ privacyPolicy: false, termsAndConditions: false, termsOfUse: false });
  const [submitLoading, setSubmitLoading] = useState(false);

  const search = useSelector((state: any) => state.search);
  const cart = useSelector((state: any) => state.cart);

  const daysDiff = moment(search.dates.endDate).diff(
    moment(search.dates.startDate),
    "days"
  );
  const destinationSubTitle =
    search.travelingTo?.city + ", " + search.travelingTo?.country || "";
  const totalGuests = search.guests.guestAdults + search.guests.guestWheelchair;
  const TravelDates = useSelector((state: any) => state.search.dates);
  const howToGetThere: HowToGetThereCartItem = cart.howToGetThere;
  const whereToStay: WhereToStayCartItem[] = cart.whereToStay;
  const whatToDo: WhatToDoCartItem[] = cart.whatToDo;
  const whereToEat: WhereToEatCartItem[] = cart.whereToEat;
  const howToMoveAround: HowToMoveAroundCartItem[] = cart.howToMoveAround;
  const whatsExtra: WhatsExtraCartItem[] = cart.whatsExtra;
  const order: Order = cart.order;
  const howToGetThereTotal = howToGetThere ? howToGetThere.value.price : 0;
  const whereToStayTotal = whereToStay.reduce((total, stayItem) => {
    return total + stayItem.value.price * daysDiff;
  }, 0);
  const whatToDoTotal = whatToDo.reduce(
    (acc, current) => acc + current.value.price * totalGuests,
    0
  );
  const whereToEatTotal = whereToEat.reduce(
    (acc, current) => acc + current.value.price,
    0
  );
  const howToMoveAroundTotal = howToMoveAround
    .flatMap((htma) => htma.selectedOffers)
    .map((offer) => offer.price)
    .reduce((acc, current) => acc + current, 0);
  const whatsExtraTotal = whatsExtra
    .flatMap((we) => we.selectedOffers)
    .map((offer) => offer.price)
    .reduce((acc, current) => acc + current, 0);

  const sum =
    howToGetThereTotal +
    whereToStayTotal +
    whatToDoTotal +
    whereToEatTotal +
    howToMoveAroundTotal +
    whatsExtraTotal;

  const onSubmit: SubmitHandler<FormType> = (form) => {
    createPaymentIntent({ totalPrice: sum }).then(async (result) => {
      const { clientSecret } = await result;
      if (clientSecret) {
        let howToGetThereItem: HowToGetThereItem | null = null;
        if (howToGetThere) {
          const {
            value: { routeDestStructure, routeRetStructure },
            searchGuid,
            flightClass,
          } = howToGetThere;
          howToGetThereItem = {
            id: howToGetThere._id,
            price: howToGetThere.value.price,
            cabinLuggage: "1 up to 8 kg",
            cancelationPolicy: "non refundable",
            checkedBuggage: "1 up to 23 kg plus 1 wheelchair",
            class: howToGetThere.flightClass,
            flightClass: flightClass,
            searchGuid: searchGuid,
            destinationAirline: {
              name: routeDestStructure.airlineName,
              code: routeDestStructure.airlineCode,
              duration: routeDestStructure.duration,
              logo: routeDestStructure.airlineLogo,
              departureFrom: routeDestStructure.flyFrom,
              departureTime: routeDestStructure.dTime,
              arrivalTo: routeDestStructure.flyTo,
              arrivalTime: routeDestStructure.aTime,
            },
            returnAirline: {
              name: routeRetStructure.airlineName,
              code: routeRetStructure.airlineCode,
              duration: routeRetStructure.duration,
              logo: routeRetStructure.airlineLogo,
              departureFrom: routeRetStructure.flyFrom,
              departureTime: routeRetStructure.dTime,
              arrivalTo: routeRetStructure.flyTo,
              arrivalTime: routeRetStructure.aTime,
            },
          } as HowToGetThereItem;
        }

        const whereToStayItem: WhereToStayItem[] =
          whereToStay.map(({ _id, inputs }) => ({
            name: _id,
            inputs: { ...inputs },
          })) || [];
        const whatToDoItems: WhatToDoItem[] =
          whatToDo.map(({ _id, inputs }) => ({
            name: _id,
            inputs: { ...inputs, date: formatDate(inputs.date) },
          })) || [];
        const whereToEatItems: WhereToEatItem[] =
          whereToEat.map(({ _id, inputs }) => ({
            name: _id,
            inputs: { ...inputs, date: formatDate(inputs.date) },
          })) || [];
        const howToMoveAroundItems: HowToMoveAroundItem[] =
          howToMoveAround.map((htma) => ({
            service: htma._id,
            selectedOffers: htma.selectedOffers,
          })) || [];
        const WhatsExtraItems: WhatsExtraItem[] =
          whatsExtra.map((we) => ({
            service: we._id,
            selectedOffers: we.selectedOffers,
          })) || [];
        const startDate = TravelDates.startDate;
        const endDate = TravelDates.endDate;
        const order = await createOrder({
          totalPrice: sum,
          totalGuests: totalGuests,
          dates: `${formatDate(startDate.format("l"))} - ${formatDate(
            endDate.format("l")
          )}`,
          customers: form.customers.map((c) => ({
            gender: c.gender,
            firstName: c.firstName,
            lastName: c.lastName,
            dateOfBirth: formatDate(c.dateOfBirth.toUTCString()),
            email: c.email,
            phoneNumber: `+${c.phoneNumberPrefix} ${c.phoneNumber}`,
            allergies: c?.allergies || "",
            travelDocument: {
              type: c.travelDocument,
              dateOfIssue: formatDate(
                c.travelDocumentDateOfIssue.toUTCString()
              ),
              expirationDate: formatDate(
                c.travelDocumentExpirationDate.toUTCString()
              ),
              issueCountry: c.countryOfIssue,
              number: c.travelDocumentNumber,
            },
            mobility: {
              aid: c.typeOfMobilityAid,
              disabilityCard: c.disabilityCard,
              percentage: c.disabilityPercentage,
              type: c.mobility || "",
            },
          })),
          flight: howToGetThereItem,
          transportation: howToMoveAroundItems,
          extra: WhatsExtraItems,
          activity: whatToDoItems,
          restaurant: whereToEatItems,
          dis_hotel: whereToStayItem,
          clientSecret,
          paymentIntent: null,
          status: "PENDING",
        });
        console.log("ORDER", order);
        setIsFormFilled(true);
        setClientSecret(clientSecret);
      }
    });
  };

  useEffect(() => {
    getStripeConfig().then(async (r) => {
      setStripePromise(loadStripe(r.publishableKey));
    });
  }, []);

  const renderTotalPerCategory = (title: string, price: number) => {
    return (
      <>
        <div className="flex items-center justify-between gap-2">
          <h4 className="text-md">{title}</h4>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 mt-1">
            {euro.format(price)}
          </div>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
      </>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-3 px-0 sm:p-6 xl:p-8 ">
        {!!howToGetThere &&
          renderTotalPerCategory("How to get there", howToGetThereTotal)}
        {!!whereToStay &&
          renderTotalPerCategory("Where to stay", whereToStayTotal)}
        {!!whatToDo?.length &&
          renderTotalPerCategory("What to do", whatToDoTotal)}
        {!!whereToEat?.length &&
          renderTotalPerCategory("Where to eat", whereToEatTotal)}
        {!!howToMoveAround?.length &&
          renderTotalPerCategory("How to move", howToMoveAroundTotal)}
        {!!whatsExtra?.length &&
          renderTotalPerCategory("What's extra", whatsExtraTotal)}

        {/* <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src="https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
            </div>
          </div>

          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                Hotel room in Tokyo, Jappan
              </span>
              <span className="text-base font-medium mt-1 block">
                The Lounge & Bar
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              2 beds · 2 baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div> */}

        <div className="flex flex-col space-y-4">
          {/* <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 day</span>
            <span>$57</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div> */}

          <div className="flex justify-between font-semibold">
            <span className="text-xl font-semibold">Total trip cost</span>
            <span>{euro.format(sum)}</span>
          </div>
          {!isFormFilled ? (
            <>
              <div className="flex flex-col space-y-2 text-[13px]">
                <div className="flex ">
                  <input
                    id="up to 3 hours"
                    name="up to 3 hours"
                    type="checkbox"
                    onChange={() =>
                      setSiteRegulation((prev) => ({
                        ...prev,
                        privacyPolicy: !prev.privacyPolicy,
                      }))
                    }
                    checked={siteRegulation.privacyPolicy}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500
                 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    htmlFor="up to 3 hours"
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      I have reviewed and accepted the{" "}
                      <a
                        target="_blank"
                        href={href.replace("checkout", "privacy-policy")}
                        className="underline hover:font-medium"
                      >
                        Privacy Policy
                      </a>
                      .
                    </span>
                  </label>
                </div>
                <div className="flex ">
                  <input
                    id="up to 3 hours"
                    name="up to 3 hours"
                    type="checkbox"
                    onChange={() =>
                      setSiteRegulation((prev) => ({
                        ...prev,
                        termsOfUse: !prev.termsOfUse,
                      }))
                    }
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500
                 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    htmlFor="up to 3 hours"
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      I have reviewed and accepted the{" "}
                      <a
                        target="_blank"
                        href={href.replace("checkout", "terms-of-use")}
                        className="underline hover:font-medium"
                      >
                        Terms of Use
                      </a>
                      .
                    </span>
                  </label>
                </div>
                <div className="flex">
                  <input
                    id="up to 3 hours"
                    name="up to 3 hours"
                    type="checkbox"
                    onChange={() =>
                      setSiteRegulation((prev) => ({
                        ...prev,
                        termsAndConditions: !prev.termsAndConditions,
                      }))
                    }
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500
                 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    htmlFor="up to 3 hours"
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      I have reviewed and accepted the{" "}
                      <a
                        target="_blank"
                        href={href.replace("checkout", "terms-and-conditions")}
                        className="underline hover:font-medium"
                      >
                        Terms & Conditions{" "}
                      </a>{" "}
                      as provided in services.
                    </span>
                  </label>
                </div>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              </div>
              <div className="pt-2 flex w-full justify-center">
                <div className="w-full">
                  <ButtonPrimary
                    form="checkoutform"
                    className="w-2/3 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-[#F47D20] hover:bg-[#2097F4] text-neutral-50"
                    disabled={
                      !siteRegulation.privacyPolicy ||
                      !siteRegulation.termsAndConditions ||
                      !siteRegulation.termsOfUse
                    }
                    type="submit"
                  >
                    Complete Booking{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </ButtonPrimary>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="pt-2 flex w-full justify-center">
                <div className="w-full">
                  {clientSecret && stripePromise && isFormFilled && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      <CheckoutForm order={order} />
                    </Elements>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="flex flex-row">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
            </div>
            <div className="flex flex-col ml-1  text-[13px]">
              <p>
                We use secure transmission and encrypted storage to protect your
                personal information.
              </p>
            </div>
          </div>
          <div>
            <div className="w-2/3 aspect-w-6 aspect-h-1 ">
              <img className="-ml-6" src={stripeSvg} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleForm = () => {
    return (
      <form
        id="checkoutform"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-4"
      >
        <Tab.Group>
          <Tab.List className="flex flex-row space-x-2">
            {[...Array(totalGuests)].map((_, index) => (
              <Tab
                className="nc-Button relative h-auto rounded-full transition-colors text-sm 
              sm:text-base font-medium px-4 py-3 sm:px-6 ui-not-selected:bg-[#F47D20] ui-selected:bg-[#2097F4] 
              text-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
                key={`customer-${index}`}
              >
                <p className="flex flex-row justify-center items-center gap-2">
                  {index === 0 ? "Main traveler" : "Additional traveler"}
                  {errors && errors.customers && errors.customers[index] ? (
                    Object.keys(errors.customers[index] || {}).length > 0 ? (
                      <img className="w-4 h-4" src={ValidationError} />
                    ) : (
                      <img className="w-4 h-4" src={ValidationSuccess} />
                    )
                  ) : null}
                </p>
              </Tab>
            ))}
          </Tab.List>
          <div className="text-sm italic -mt-3">
            <p>
              * booking(s) and confirmation(s) will fall under main traveler's
              personal details unless otherwise required
            </p>
          </div>
          <Tab.Panels>
            {[...Array(totalGuests)].map((_, index) => (
              <Tab.Panel className="flex flex-col space-y-6" key={index}>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="gender"
                  >
                    Gender (as specified in your travel document)
                  </label>
                  <select
                    id="gender"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 
focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.gender`)}
                    onClick={() => clearErrors(`customers.${index}.gender`)}
                  >
                    <option value="">Select Gender</option>
                    {genders.map((gender) => (
                      <option key={gender} value={gender}>
                        {gender}
                      </option>
                    ))}
                  </select>
                  {errors.customers?.[index]?.gender && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.gender?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="firstname"
                  >
                    First Name <span className="mx-1">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring 
focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25
dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.firstName`)}
                    onFocus={() => clearErrors(`customers.${index}.firstName`)}
                  />
                  {errors.customers?.[index]?.firstName && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.firstName?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="lastname"
                  >
                    Last Name<span className="mx-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    id="lastname"
                    {...register(`customers.${index}.lastName`)}
                    onFocus={() => clearErrors(`customers.${index}.lastName`)}
                  />
                  {errors.customers?.[index]?.lastName && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.lastName?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="dob"
                  >
                    Date of birth<span className="mx-1">*</span>
                  </label>
                  <input
                    type="date"
                    id="dob"
                    className="block w-full border-neutral-200 focus:border-primary-300 
focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 
dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl 
text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.dateOfBirth`)}
                    onFocus={() =>
                      clearErrors(`customers.${index}.dateOfBirth`)
                    }
                  />
                  {errors.customers?.[index]?.dateOfBirth && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.dateOfBirth?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="email"
                  >
                    Email<span className="mx-1">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.email`)}
                    onFocus={() => clearErrors(`customers.${index}.email`)}
                  />
                  {errors.customers?.[index]?.email && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.email?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                      htmlFor="phoneNumber"
                    >
                      Phone number<span className="mx-1">*</span>
                    </label>
                    <div className="relative ">
                      <select
                        className="absolute top-0 left-0 border-none focus:ring-0 w-4/12 truncate h-full bg-transparent rounded-2xl text-sm"
                        {...register(`customers.${index}.phoneNumberPrefix`)}
                        onFocus={() =>
                          clearErrors(`customers.${index}.phoneNumberPrefix`)
                        }
                      >
                        <option value="">Select Country</option>
                        {COUNTRIES.map(({ mobilePrefixCode, name }) => (
                          <option
                            key={`mobilePrefixCode-${mobilePrefixCode}-${name}`}
                            value={mobilePrefixCode}
                          >
                            {" "}
                            <span>+{mobilePrefixCode}</span>{" "}
                            <span>({name})</span>
                          </option>
                        ))}
                      </select>
                      <input
                        type="number"
                        id="phoneNumber"
                        className="w-full h-full border-1  focus:border-primary-300
focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 
dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl 
text-sm font-normal  px-4 py-3 pl-60"
                        {...register(`customers.${index}.phoneNumber`)}
                        onFocus={() =>
                          clearErrors(`customers.${index}.phoneNumber`)
                        }
                      />
                    </div>
                  </div>
                  {errors.customers?.[index]?.phoneNumber && (
                    <Validation
                      classNameCustom="absolute left-1/4"
                      text={
                        errors.customers?.[index]?.phoneNumber?.message ||
                        "This field is required"
                      }
                    />
                  )}
                  {errors.customers?.[index]?.phoneNumberPrefix && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.phoneNumberPrefix?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>

                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="gender"
                  >
                    Travel Document<span className="mx-1">*</span>
                  </label>
                  <select
                    id="travelDocument"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 
focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.travelDocument`)}
                    onFocus={() =>
                      clearErrors(`customers.${index}.travelDocument`)
                    }
                  >
                    <option value="">Select travel document</option>
                    {travelDocuments.map((travelDocument) => (
                      <option key={travelDocument} value={travelDocument}>
                        {travelDocument}
                      </option>
                    ))}
                  </select>
                  {errors.customers?.[index]?.travelDocument && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.travelDocument?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="travelDocumentNumber"
                  >
                    Travel Document Number<span className="mx-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50
        bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl 
        text-sm font-normal h-11 px-4 py-3"
                    id="travelDocumentNumber"
                    {...register(`customers.${index}.travelDocumentNumber`)}
                    onFocus={() =>
                      clearErrors(`customers.${index}.travelDocumentNumber`)
                    }
                  />
                  {errors.customers?.[index]?.travelDocumentNumber && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.travelDocumentNumber
                          ?.message || "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="countryOfIssue"
                  >
                    Country of issue<span className="mx-1">*</span>
                  </label>
                  <select
                    id="countryOfIssue"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 
focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.countryOfIssue`)}
                    onFocus={() =>
                      clearErrors(`customers.${index}.countryOfIssue`)
                    }
                  >
                    <option value="">Select Country of issue</option>
                    {countries.map((country, index) => (
                      <option
                        key={`country-${country} - ${index}`}
                        value={country}
                      >
                        {country}
                      </option>
                    ))}
                  </select>
                  {errors.customers?.[index]?.countryOfIssue && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.countryOfIssue?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="travelDocumentDateOfIssue"
                  >
                    Date of issue<span className="mx-1">*</span>
                  </label>
                  <input
                    type="date"
                    id="travelDocumentDateOfIssue"
                    className="block w-full border-neutral-200 focus:border-primary-300 
focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 
dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl 
text-sm font-normal h-11 px-4 py-3"
                    {...register(
                      `customers.${index}.travelDocumentDateOfIssue`
                    )}
                    onFocus={() =>
                      clearErrors(
                        `customers.${index}.travelDocumentDateOfIssue`
                      )
                    }
                  />
                  {errors.customers?.[index]?.travelDocumentDateOfIssue && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.travelDocumentDateOfIssue
                          ?.message || "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="travelDocumentExpirationDate"
                  >
                    Date of expiration<span className="mx-1">*</span>
                  </label>
                  <input
                    type="date"
                    id="travelDocumentExpirationDate"
                    className="block w-full border-neutral-200 focus:border-primary-300 
focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 
dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl 
text-sm font-normal h-11 px-4 py-3"
                    {...register(
                      `customers.${index}.travelDocumentExpirationDate`
                    )}
                    onFocus={() =>
                      clearErrors(
                        `customers.${index}.travelDocumentExpirationDate`
                      )
                    }
                  />
                  {errors.customers?.[index]?.travelDocumentExpirationDate && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.travelDocumentExpirationDate
                          ?.message || "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="typeOfMobilityAid"
                  >
                    Type of mobility Aid<span className="mx-1">*</span>
                  </label>
                  <select
                    id="typeOfMobilityAid"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 
focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.typeOfMobilityAid`)}
                    onFocus={() =>
                      clearErrors(`customers.${index}.typeOfMobilityAid`)
                    }
                  >
                    <option value="">Select type of mobility</option>
                    {typeOfMobilityAid.map((mobilityType) => (
                      <option key={mobilityType} value={mobilityType}>
                        {mobilityType}
                      </option>
                    ))}
                  </select>
                  {errors.customers?.[index]?.typeOfMobilityAid && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.typeOfMobilityAid?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="mobility"
                  >
                    Mobility<span className="mx-1">*</span>
                  </label>
                  <select
                    id="mobility"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 
focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.mobility`)}
                    onFocus={() => clearErrors(`customers.${index}.mobility`)}
                  >
                    <option value="">Select mobility</option>
                    {mobility.map(({ name, description }) => (
                      <option key={name} value={name}>
                        {name}
                        {description !== "" ? ` : ${description}` : ""}
                      </option>
                    ))}
                  </select>
                  {errors.customers?.[index]?.mobility && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.mobility?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="disabilityPercentage"
                  >
                    Disability rate percentage
                    <span className="mx-1">*</span>
                  </label>
                  <input
                    type="number"
                    defaultValue={0}
                    id="disabilityPercentage"
                    className="block w-full border-neutral-200 focus:border-primary-300 
focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 
dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl 
text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.disabilityPercentage`)}
                    onFocus={() =>
                      clearErrors(`customers.${index}.disabilityPercentage`)
                    }
                  />
                  {errors.customers?.[index]?.disabilityPercentage && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.disabilityPercentage
                          ?.message || "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="disabilityCard"
                  >
                    I have a disability card, justifying the above disability
                    rate percentage.
                    <span className="mx-1">*</span>
                  </label>
                  <select
                    id="disabilityCard"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 
focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 
rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.disabilityCard`)}
                    onFocus={() =>
                      clearErrors(`customers.${index}.disabilityCard`)
                    }
                  >
                    <option value="">Select</option>
                    {yesOrNo.map((selection) => (
                      <option key={selection} value={selection}>
                        {selection}
                      </option>
                    ))}
                  </select>
                  {errors.customers?.[index]?.disabilityCard && (
                    <Validation
                      classNameCustom="absolute"
                      text={
                        errors.customers?.[index]?.disabilityCard?.message ||
                        "This field is required"
                      }
                    />
                  )}
                </div>
                <div>
                  <label
                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    htmlFor="allergies"
                  >
                    Allergies
                  </label>
                  <input
                    type="text"
                    id="allergies"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3"
                    {...register(`customers.${index}.allergies`)}
                    onFocus={() => clearErrors(`customers.${index}.allergies`)}
                  />
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </form>
    );
  };

  return (
    <div
      className={`nc-CheckOutPage  ${className} container`}
      data-nc-id="CheckOutPage"
    >
      <Seo
        name="Checkout | Orbito Travel"
        description="Complete your booking with Orbito Travel's secure checkout process. Explore our user-friendly platform for finalizing your accessible travel arrangements. Start your journey towards unforgettable experiences today!"
        title="Checkout | Orbito Travel"
        type="page"
      />
      {/* <SectionHeroArchivePage
        currentPage="Flights"
        currentTab="Flights"
        title="Complete your trip to"
        subTitle={destinationSubTitle}
        rightImage={
          "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/02.png"
        }
        className="pt-10 lg:pt-16 z-0"
      /> */}
      <main className="mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row relative">
        {sum === 0 ? (
          <div className="text-xl font-semibold">The cart is empty</div>
        ) : (
          <>
            <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
              <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                {/* <h2 className="text-3xl lg:text-4xl font-semibold">Checkout</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div> */}
                <div className="mt-6">{handleForm()}</div>
              </div>
            </div>
            <div className="block flex-grow w-1/5">
              <div className="sticky top-52 ">{renderSidebar()}</div>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default CheckOutPage;
