import { FC } from "react";
import StayCard from "components/StayCard/StayCard";
import { StayDetailType } from "data/types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export interface SectionGridFilterCardProps {
  className?: string;
  data: StayDetailType[];
  isLoading?: boolean;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
  isLoading,
}) => {
  const location = useLocation();
  // const searchCity = useSelector(
  //   (state: any) => state.search.travelingTo?.city
  // );
  const selectedStayId = useSelector(
    (state: any) => state.cart.whereToStay?._id
  );

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* <Heading2 /> */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data
          .filter((stay) => stay.city === "Larnaca")
          .map((stay: any) => (
            <StayCard
              cardType={
                location.pathname.includes("what-to-do") ? "OTHER" : "HOTELS"
              }
              key={stay._id}
              data={stay}
              className={!stay.isAvailable ? "grayscale" : ""}
              internalLink="/where-to-stay"
              isOnCart={selectedStayId === stay._id}
              isLoading={isLoading}
            />
          ))}
      </div>

      {!isLoading && data.length === 0 && (
        <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
          <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              Sorry, no properties to displayed for your convenience.
            </span>
          </div>
        </div>
      )}
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
