import { Order } from "containers/CheckOutPage/CheckOutPage";
import { DataDetailType, HowToGetThereCart } from "data/types";
import {
  HowToGetThereCartItem,
  HowToMoveAroundCartItem,
  WhatToDoCartItem,
  WhatsExtraCartItem,
  WhereToEatCartItem,
  WhereToStayCartItem,
} from "store/reducers/cart";

export const addHowToGetThere = (item: HowToGetThereCartItem) => {
  return {
    type: "ADD_HOW_TO_GET_THERE",
    payload: item,
  };
};

export const removeHowToGetThere = () => {
  return {
    type: "REMOVE_HOW_TO_GET_THERE",
  };
};

// WHERE TO STAY

export const addWhereToStay = (item: WhereToStayCartItem) => {
  return {
    type: "ADD_WHERE_TO_STAY",
    payload: item,
  };
};

export const removeWhereToStay = (id:string) => {
  return {
    type: "REMOVE_WHERE_TO_STAY",
    payload: id,
  };
};

// WHAT TO DO

export const addWhatToDo = (item: WhatToDoCartItem) => {
  return {
    type: "ADD_WHAT_TO_DO",
    payload: item,
  };
};

export const removeWhatToDo = (_id: string) => {
  return {
    type: "REMOVE_WHAT_TO_DO",
    payload: _id,
  };
};

// WHAT TO EAT

export const addWhereToEat = (item: WhereToEatCartItem) => {
  return {
    type: "ADD_WHAT_TO_EAT",
    payload: item,
  };
};

export const removeWhereToEat = (_id: string) => {
  return {
    type: "REMOVE_WHAT_TO_EAT",
    payload: _id,
  };
};

// HOW TO MOVE

export const addHowToMove = (item: HowToMoveAroundCartItem) => {
  return {
    type: "ADD_HOW_TO_MOVE",
    payload: item,
  };
};

export const removeHowToMove = (_id: string) => {
  return {
    type: "REMOVE_HOW_TO_MOVE",
    payload: _id,
  };
};

// WHATS EXTRA

export const addWhatsExtra = (item: WhatsExtraCartItem) => {
  return {
    type: "ADD_WHATS_EXTRA",
    payload: item,
  };
};

export const removeWhatsExtra = (_id: string) => {
  return {
    type: "REMOVE_WHATS_EXTRA",
    payload: _id,
  };
};

// ADD ORDER

export const addOrder = (item: Order) => {
  return {
    type: "ADD_ORDER",
    payload: item,
  };
};

// CART GENERAL

export const addToCart = (object: {
  item: DataDetailType;
  storeKey: string;
}) => {
  return {
    type: "ADD_TO_CART",
    payload: object.item,
    storeKey: object.storeKey,
  };
};

export const removeFromCart = (object: {
  item: DataDetailType;
  storeKey: string;
}) => {
  return {
    type: "REMOVE_FROM_CART",
    payload: object.item,
    storeKey: object.storeKey,
  };
};

export const resetCart = () => {
  return {
    type: "RESET_CART",
  };
};
