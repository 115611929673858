// import React from "react";
import MyRouter from "routers/index";
import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { useEffect, useState } from "react";

import {
  useDispatch,
  // useSelector
} from "react-redux";
import { setUser, logout } from "store/actions/auth";
import React from "react";
import { BrowserRouter, Router } from "react-router-dom";
import SiteHeader from "containers/SiteHeader";
import { SearchParamsProvider } from "context/SearchParamsContext";
import Footer from "shared/Footer/Footer";
import MyRoutes from "routers/index";
import ScrollToTop from "routers/ScrollToTop";
import Seo from "components/Seo/Seo";
import { HotJarProvider } from "context/HotJarContext";
import { StylesObj, TourProvider, components } from "@reactour/tour";
Amplify.configure(awsExports);

function Content() {
  const noFlight = localStorage.getItem("no_flight") === "true";

  return (
    <components.Content
      content={
        <div className="flex flex-col gap-2 rounded-md">
          <p className="flex-1 font-bold">
            We make it easy to find what you're looking for!
          </p>
          <p>
            Click any of the {noFlight ? 5 : 6} icons for quick access to
            specific service categories.{" "}
          </p>
          <p>
            To see more options, click the back <br></br> or forward arrows.
          </p>
        </div>
      }
      currentStep={0}
      setCurrentStep={() => undefined}
    />
  );
}

function App() {
  const [stripePromise, setStripePromise] = useState<string | null>(null);
  const dispatch = useDispatch();

  // const authState = (state: any) => state.auth;
  // const auth = useSelector(authState);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          dispatch(setUser(user));
        } else {
          dispatch(logout());
        }
      } catch (error) {
        dispatch(logout());
      }
    };
    updateUser();
  }, [dispatch]);
  return (
    <BrowserRouter>
      <SearchParamsProvider>
        <HotJarProvider>
          {/* <TourProvider
            components={{ Content }}
            beforeClose={() => localStorage.setItem("tourIsClosed", "true")}
            steps={steps}
            disableDotsNavigation={true}
            disableKeyboardNavigation={true}
            styles={{
              badge: (base) => ({
                ...base,
                color: "white",
                backgroundColor: "orange",
                display: "none",
                borderRadius: "0.375rem",
              }),

              maskArea: (base) => ({
                ...base,
                rx: 20,
                border: "2px",
                borderRadius: "50%",
              }),
              popover: (base) => ({
                ...base,
                alignContent: "center",
                alignmentBaseline: "central",
                alignItems: "center",
                paddingTop: "20px",
                marginTop: "12px",
                maxWidth: "450px",
                padding: "20px 30px 2px",
                borderRadius: "0.375rem",
              }),
              dot: (base) => ({
                ...base,
                color: "white",
                backgroundColor: "orange",
                display: "none",
              }),
              arrow: (base) => ({
                display: "none",
              }),

              close: (base) => ({
                ...base,
                position: "absolute",
                top: 5,
                right: 5,
                width: 12,
                height: 12,
              }),
            }}
          > */}
          {!window.location.search.includes("is_preview") ? (
            <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
              <ScrollToTop />
              <SiteHeader />

              <MyRoutes />
              <Footer />
            </div>
          ) : (
            <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
              <MyRoutes />
            </div>
          )}
          {/* </TourProvider> */}
        </HotJarProvider>
      </SearchParamsProvider>
    </BrowserRouter>
  );
}

const steps = [
  {
    selector: ".first-step",
    content: `We make it easy to find what you're looking for!

    Click any of the 6 icons for quick access to specific service categories.  
    
    To see more options, click the back or forward arrows`,
  },
  // ...
];
export default App;
