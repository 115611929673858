import { FC, useState, useEffect } from "react";
import ButtonSubmit from "./ButtonSubmit";
import { PathName } from "routers/types";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import { useSelector, useDispatch } from "react-redux";
import { setStay } from "../../store/actions/stay";
import { setStayCurrentPrice } from "../../store/actions/stayCurrentPrice";
import { setStayCurrentCharges } from "../../store/actions/stayCurrentCharges";
import { setStayCurrentNON } from "../../store/actions/stayCurrentNON";
import { setStayCurrentTotalPrice } from "../../store/actions/stayCurrentTotalPrice";
import { setStayCurrentTotalPriceWithCharges } from "../../store/actions/stayCurrentTotalPriceWithCharges";
import { setStayCancellationTypes } from "../../store/actions/stayCancellationTypes";
import { Popover, Transition } from "@headlessui/react";

export interface CancellationInputProps {
  onChange?: (date: moment.Moment | null) => void;
  defaultFocus?: boolean;
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: PathName;
  hasButtonSubmit?: boolean;
  isDisabled?: boolean;
  setSelectedCancelation: React.Dispatch<React.SetStateAction<string>>;
  data?: any[];
  dis_rate?: any[];
}

const CancellationInput: FC<CancellationInputProps> = ({
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
  isDisabled = false,
  defaultFocus = false,
  setSelectedCancelation,
  data = [],
  dis_rate = [],
}) => {
  const dispatch = useDispatch();

  const [cancellationOptions, setCancellationOptions] = useState(() => {
    const uniqueCancellations = new Map();
    if (dis_rate) {
      dis_rate.forEach((rate) => {
        const cancellationType = rate.refundable
          ? "Refundable"
          : "Non-refundable";
        if (!uniqueCancellations.has(cancellationType)) {
          uniqueCancellations.set(cancellationType, {
            id: rate._id,
            name: cancellationType,
            checked: false,
          });
        }
      });
    }
    return Array.from(uniqueCancellations.values());
  });

  useEffect(() => {
    if (
      cancellationOptions.length > 0 &&
      !cancellationOptions.some((option) => option.checked)
    ) {
      setSelectedCancelation(cancellationOptions[0].name);
      setCancellationOptions((prevOptions) =>
        prevOptions.map((option, index) => ({
          ...option,
          checked: index === 0,
        }))
      );
    }
  }, [cancellationOptions, setSelectedCancelation]);

  const handleChange = (name: string) => {
    const updatedOptions = cancellationOptions.map((option) => {
      if (option.name === name) {
        const newCheckedState = !option.checked;
        setSelectedCancelation(newCheckedState ? name : "");
        return { ...option, checked: newCheckedState };
      }
      return { ...option, checked: false };
    });

    setCancellationOptions(updatedOptions);
  };

  return (
    <div className="w-1/2 h-24">
      <Popover className="flex relative">
        {({ open }) => (
          <>
            <Popover.Button className=" p-4 w-1/2 flex-1 flex text-left items-center space-x-3">
              <div className="text-neutral-300 dark:text-neutral-400">
                <div className="text-4xl">
                  <i className="la la-clipboard-list"></i>
                </div>
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {cancellationOptions.find((option) => option.checked)?.name ||
                    "Non-refundable"}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  Cancellation
                </span>
              </div>
            </Popover.Button>
            <Transition
              as="div"
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-max max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
                <div className="overflow-hidden">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {cancellationOptions.map((item) => (
                      <div key={item.id} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.id)}
                          checked={item.checked}
                          onChange={() => handleChange(item.name)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default CancellationInput;
