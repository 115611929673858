import { FC, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import { AnchorDirectionShape } from "react-dates";
import { Pathname } from "react-router-dom";

export interface ExperiencesMealInputProps {
  onChange?: (date: moment.Moment | null) => void;
  defaultFocus?: boolean;
  fieldClassName?: string;
  onFocusChange?: (focused: boolean) => void;
  className?: string;
  anchorDirection?: AnchorDirectionShape;
  isDisabled?: boolean;
  hasButtonSubmit?: boolean;
  buttonSubmitHref?: Pathname;
  setSelectedMeals: React.Dispatch<React.SetStateAction<string>>;
  data?: any[];
  dis_rate?: any[];
}

const ExperiencesMealInput: FC<ExperiencesMealInputProps> = ({
  hasButtonSubmit = true,
  buttonSubmitHref = "/listing-stay-map",
  isDisabled = false,
  onChange,
  defaultFocus = false,
  onFocusChange,
  anchorDirection,
  setSelectedMeals,
  className = "",
  dis_rate = [],
  fieldClassName = "[ nc-hero-field-padding ]",
  data = [],
}) => {
  const [mealOptions, setMealOptions] = useState(() => {
    const uniqueMeals = new Map();
    if (dis_rate) {
      dis_rate.forEach((rate) => {
        if (!uniqueMeals.has(rate.mealPlan)) {
          uniqueMeals.set(rate.mealPlan, {
            id: rate._id,
            name: rate.mealPlan,
            checked: false,
          });
        }
      });
    }
    return Array.from(uniqueMeals.values());
  });

  useEffect(() => {
    if (
      mealOptions.length > 0 &&
      !mealOptions.some((option) => option.checked)
    ) {
      setSelectedMeals(mealOptions[0].name);
      setMealOptions((prevOptions) =>
        prevOptions.map((option, index) => ({
          ...option,
          checked: index === 0,
        }))
      );
    }
  }, [mealOptions, setSelectedMeals]);

  const handleChange = (name: string) => {
    setSelectedMeals(name);

    const updatedOptions = mealOptions.map((option) =>
      option.name === name
        ? { ...option, checked: true }
        : { ...option, checked: false }
    );

    setMealOptions(updatedOptions);
  };

  return (
    <div className="w-1/2 h-24">
      <Popover className="flex relative h-full">
        {({ open }) => (
          <>
            <div
              className="flex-1 flex items-center focus:outline-none cursor-pointer 
             "
            >
              <Popover.Button className="p-4   flex-1 flex text-left items-center space-x-3">
                <div className="text-neutral-300 dark:text-neutral-400">
                  <div className="text-4xl">
                    <i className="la la-utensils"></i>
                  </div>
                </div>
                <div className="flex-grow">
                  <span className="block xl:text-lg font-semibold">
                    {mealOptions.find((option) => option.checked)?.name ||
                      "No meals"}
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    Meals
                  </span>
                </div>
              </Popover.Button>
            </div>
            <Transition
              as="div"
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-max max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
                <div className="overflow-hidden">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {mealOptions.map((item) => (
                      <div key={item.id} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.id)}
                          checked={item.checked}
                          onChange={() => handleChange(item.name)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ExperiencesMealInput;
