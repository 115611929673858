import { Popover, Transition } from "@headlessui/react";
import { max } from "lodash";
import React, { useState, useEffect, useRef } from "react";

interface RoomCountInputProps {
  roomType: string;
  roomCount: number;
  setRoomCount: React.Dispatch<React.SetStateAction<number>>;
  maxRooms: number;
  disabled: boolean;
}

const RoomCountInput: React.FC<RoomCountInputProps> = ({
  roomType,
  roomCount,
  setRoomCount,
  maxRooms,
  disabled,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const roomInputRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const onFocus = () => setIsFocused(true);
    const onBlur = () => setIsFocused(false);

    const inputElement = roomInputRef.current;

    inputElement?.addEventListener("focus", onFocus);
    inputElement?.addEventListener("blur", onBlur);

    // Cleanup event listeners
    return () => {
      inputElement?.removeEventListener("focus", onFocus);
      inputElement?.removeEventListener("blur", onBlur);
    };
  }, []);

  const handleIncrement = () => {
    setRoomCount((prev: any) => prev + 1);
  };

  const handleDecrement = () => {
    if (roomCount > 0) {
      setRoomCount((prev: any) => prev - 1);
    }
  };

  return (
    <div className="w-1/2 h-24">
      <Popover className="flex relative h-full">
        {({ open }) => (
          <>
            <div
              className="flex-1 flex items-center focus:outline-none cursor-pointer
                "
            >
              <Popover.Button
                disabled={maxRooms === 0 && roomCount === 0}
                className="p-4  flex-1 flex text-left items-center space-x-3"
              >
                <div className="text-neutral-300 dark:text-neutral-400">
                  <div className="text-4xl">
                    <i className="la la-bed"></i>
                  </div>
                </div>
                <div className="flex-grow">
                  <span
                    className={`block xl:text-lg font-semibold ${
                      maxRooms === 0 && roomCount === 0
                        ? "text-slate-500"
                        : "text-slate-1000"
                    }`}
                  >
                    {maxRooms === 0 && roomCount === 0
                      ? "Not Available"
                      : roomCount}
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    {roomType}
                  </span>
                </div>
              </Popover.Button>
            </div>
            <Transition
              as="div"
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-max max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
                <div className="overflow-hidden">
                  <div className="flex flex-row gap-3">
                    <button
                      type="button"
                      onClick={handleDecrement}
                      disabled={roomCount <= 0}
                      style={{
                        color: roomCount <= 0 ? "lightgrey" : "black",
                        borderColor: roomCount <= 0 ? "lightgrey" : "black",
                      }}
                      className="border  rounded-full px-2 
                  border-slate-700 text-slate-700"
                    >
                      -
                    </button>
                    <div className={`w-4 text-center `}>{roomCount}</div>
                    <button
                      type="button"
                      style={{
                        color: maxRooms === 0 ? "lightgrey" : "black",
                        borderColor: maxRooms === 0 ? "lightgrey" : "black",
                      }}
                      onClick={handleIncrement}
                      disabled={disabled || maxRooms === 0}
                      className="border border-secondary-500 rounded-full px-2"
                    >
                      +
                    </button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default RoomCountInput;
