import React from "react";
import ReactPlaceholder from "react-placeholder";
import { TextBlock } from "react-placeholder/lib/placeholders";
import { useDispatch, useSelector } from "react-redux";
type TermsAndConditionsSectionProps = {
  isLoading: boolean;
  cancelationPolicy?: string;
  perDayData?: any;
  notes?: string;
  thingsToKnow?: string;
  dayName?: string;
  check?: {
    in?: string;
    out?: string;
  };
  dis_is_accessible?: boolean;
};

const TermsAndConditionsSection = ({
  isLoading,
  cancelationPolicy,
  notes,
  perDayData,
  thingsToKnow,
  dayName,
  check,
  dis_is_accessible,
}: TermsAndConditionsSectionProps) => {
  console.log("TermsAndConditionsSection", cancelationPolicy);
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";

  const textTwoRowsPlaceholder = (
    <div className="text-2-placeholder">
      <TextBlock color={placeholderColor} rows={2} />
    </div>
  );

  const textFourRowsPlaceholder = (
    <div className="text-4-placeholder">
      <TextBlock color={placeholderColor} rows={4} />
    </div>
  );
  return (
    <div
      className={`listingSection__wrap ${
        dis_is_accessible ? "bg-orange-100" : "bg-white"
      }`}
    >
      {/* HEADING */}
      <h2 className="text-2xl font-semibold">Terms and Conditions</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      {/* CONTENT */}
      {cancelationPolicy && cancelationPolicy !== "" ? (
        <>
          <div>
            <h4 className="text-lg font-semibold">Cancellation policy</h4>

            {/* <h4 className="text-lg font-semibold">Cancellation policy</h4> */}
            <ReactPlaceholder
              ready={!isLoading}
              customPlaceholder={textTwoRowsPlaceholder}
              showLoadingAnimation
            >
              <span className="block text-neutral-500 dark:text-neutral-400">
                {cancelationPolicy && (
                  <div
                    className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 "
                    dangerouslySetInnerHTML={{ __html: cancelationPolicy }}
                  />
                )}
              </span>
            </ReactPlaceholder>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        </>
      ) : null}
      {perDayData &&
      dayName &&
      perDayData[dayName] &&
      perDayData[dayName].paymentMethod ? (
        <>
          <div>
            <h4 className="text-lg font-semibold">Payment Method</h4>
            <ReactPlaceholder
              ready={!isLoading}
              customPlaceholder={textTwoRowsPlaceholder}
              showLoadingAnimation
            >
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                {perDayData[dayName].paymentMethod}
              </span>
            </ReactPlaceholder>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        </>
      ) : null}
      {check?.in && check.out ? (
        <>
          <div>
            <h4 className="text-lg font-semibold">Check-in time</h4>
            <ReactPlaceholder
              ready={!isLoading}
              customPlaceholder={textFourRowsPlaceholder}
              showLoadingAnimation
            >
              <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
                <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
                  <span>Check-in</span>
                  <span>{check.in}</span>
                </div>
                <div className="flex space-x-10 justify-between p-3">
                  <span>Check-out</span>
                  <span>{check.out}</span>
                </div>
              </div>
            </ReactPlaceholder>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        </>
      ) : null}
      {notes && notes !== "" ? (
        <>
          <div>
            <h4 className="text-lg font-semibold">Notes</h4>
            <div className="prose sm:prose">
              <ReactPlaceholder
                ready={!isLoading}
                customPlaceholder={textTwoRowsPlaceholder}
                showLoadingAnimation
              >
                <div
                  className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2"
                  dangerouslySetInnerHTML={{ __html: notes }}
                />
              </ReactPlaceholder>
            </div>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
        </>
      ) : null}

      {thingsToKnow && thingsToKnow !== "" ? (
        <>
          <div>
            <h4 className="text-lg font-semibold">Things to Know</h4>
            <div className="prose sm:prose">
              <ReactPlaceholder
                ready={!isLoading}
                customPlaceholder={textTwoRowsPlaceholder}
                showLoadingAnimation
              >
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                  {thingsToKnow && (
                    <div
                      className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2"
                      dangerouslySetInnerHTML={{ __html: thingsToKnow }}
                    />
                  )}
                </span>
              </ReactPlaceholder>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TermsAndConditionsSection;
