import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { Order } from "containers/CheckOutPage/CheckOutPage";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { finalizeOrder } from "services/payment";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { resetCart } from "store/actions/cart";
import { resetSearch } from "store/actions/search";
import { euro } from "utils/currency";
import { useReactToPrint } from "react-to-print";
import GreeceImage from "images/DIS_image.jpg";

// import NewOrderEmail from "email/NewOrderEmail";
import moment from "moment";
import { useCustomSearchParams } from "context/SearchParamsContext";
export interface PayPageProps {
  className?: string;
}
const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const dispach = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tawkApiRef = useRef(null);
  let componentRef = useRef<HTMLDivElement>(null);
  const { getStringParams, data } = useCustomSearchParams();
  // const cart = useSelector((state: any) => state.cart);
  const params = new URLSearchParams(location.search);
  const paymentIntent = params.get("payment_intent");
  const clientSecret = params.get("payment_intent_client_secret");
  const [order, setOrder] = useState<Order>();

  const datatemp = data();

  const destination = "DIS Festival Larnaca, Cyprus";

  useEffect(() => {
    const finalizeOrderAsync = async () => {
      try {
        if (clientSecret && paymentIntent) {
          const { order, paymentMethod } = await finalizeOrder(
            clientSecret,
            paymentIntent
          );
          if (order) {
            setOrder(order);

            resetState();
          } else {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error finalizing order:", error);
      }
    };

    finalizeOrderAsync();
  }, [clientSecret, paymentIntent]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const resetState = () => {
    dispach(resetCart());
    dispach(resetSearch());
  };

  const { customers, dates, totalPrice, _id, status, orderId } = order || {}; // Destructure order

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-3 px-0 sm:p-6 xl:p-8 ">
        <div>
          <ButtonPrimary
            onClick={handlePrint}
            className="w-full bg-neutral-500 hover:bg-[#F47D20]"
          >
            Print this page
          </ButtonPrimary>
        </div>
        <div>
          <a
            href="mailto:subscriptions@orbito.travel"
            className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70
              text-neutral-50 w-full 
             bg-neutral-500 hover:bg-[#F47D20] focus:outline-none focus:ring-2 
             focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
          >
            Send us a message
          </a>
        </div>
        {/* <div>
          <ButtonPrimary className="w-full ">Go to my account</ButtonPrimary>
        </div> */}
      </div>
    );
  };

  const renderRowDetail = (title: string, value: string) => {
    return (
      <div className="flex flex-row ">
        <div className="flex-1">{title}</div>
        <div className="flex-1">{value}</div>
      </div>
    );
  };

  const renderMainSection = () => {
    return (
      <div
        ref={componentRef}
        className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 space-y-5"
      >
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
          <div>
            <p>{orderId}</p>
          </div>
          <img src={GreeceImage} className="" />
          <div className="flex flex-col space-y-3">
            {Array.isArray(customers) ? (
              <>
                {customers.length === 1 &&
                  renderRowDetail(
                    "Main Traveler",
                    order!.customers[0]?.lastName
                  )}
                {customers.length > 1 &&
                  renderRowDetail(
                    "Additional Traveler",
                    order!.customers[0]?.lastName
                  )}
              </>
            ) : null}
            {renderRowDetail("Trip to", destination)}
            {dates &&
              renderRowDetail("Travel dates", dates.replace("-", "until"))}
            {totalPrice &&
              renderRowDetail("Total amount", euro.format(totalPrice))}
            {renderRowDetail("Payment Method", "Debit/Credit Card")}
            {/* {renderRowDetail("Date of authorization", "Value")}
          {renderRowDetail("Time of authorization", "Value")} */}
            {status && renderRowDetail("Status", "Payment authorization")}
            {/* {_id && renderRowDetail("Booking id", _id)} */}
          </div>
        </div>
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8  px-0 sm:p-6 xl:p-8">
          <h2 className="text-lg font-black"> Thank you for your booking </h2>
          <div className="border-b border-neutral-200 dark:border-neutral-700 w-1/3"></div>
          <p>
            Your credit/debit card has been successfully authorized and your
            reservation is getting a final check to ensure a smooth and
            worry-free trip.
            <span className="block mt-5">
              This extra step ensures all your chosen dates, options and details
              are perfect before your final payment.
            </span>
          </p>
        </div>
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
          <h2 className="text-lg font-black"> What happens next</h2>
          <div className="border-b border-neutral-200 dark:border-neutral-700 w-1/3"></div>
          <p>
            Within 24 hours, you will receive a final confirmation email with
            all your booking details.
            <span className="block mt-5">
              Full payment will be processed when your booking is confirmed.
            </span>
          </p>
        </div>
        <p className="width-full text-center">
          orbito travel private company | 75 Thessalonikis Street, 183 45
          Moschato, Athens Greece <br /> hello@orbito.travel | +30 215 215 3103{" "}
          <br />
          IATA code : 27219334 | G.N.T.O. code : 0206E70000866401 <br />
          Greek Commercial Registry code : 164601403000
        </p>
      </div>
    );
  };

  return (
    <div
      className={`nc-CheckOutPage  ${className} container`}
      data-nc-id="CheckOutPage"
    >
      {/* <SectionHeroArchivePage
        currentPage="Flights"
        currentTab="Flights"
        title="We are now finalizing your trip to"
        subTitle={destination}
        rightImage={
          "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/02.png"
        }
        className="pt-10 lg:pt-16 z-0"
      /> */}
      <main className="mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row relative">
        {renderMainSection()}
        <div className="block flex-grow w-1/5">
          <div className="sticky top-52 ">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default PayPage;
