import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { AuthorType, PostDataType, TaxonomyType } from "data/types";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";

import HIW1img from "images/HIW2-1.png";
import HIW2img from "images/HIW2-2.png";
import HIW3img from "images/HIW2-3.png";
import HIW1imgDark from "images/HIW2-1-dark.png";
import HIW2imgDark from "images/HIW2-2-dark.png";
import HIW3imgDark from "images/HIW2-3-dark.png";
import rightImgPng from "images/our-features-2.png";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionVideos from "./SectionVRs";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import { DEMO_POSTS } from "data/posts";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { resetSearch } from "store/actions/search";
import SectionVRs from "./SectionVRs";
import { resetCart } from "store/actions/cart";
import { useLocation } from "react-router-dom";
import Seo from "components/Seo/Seo";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import SearchForm from "components/HeroSearchForm/new/SearchForm";
import { setHotels } from "store/actions/hotels";
import BlogPage from "containers/BlogPage/BlogPage";
import SectionSliderCategories from "components/SectionSliderNewCategories/SectionSliderCategories";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import useScrollPosition from "hooks/useScrollPosition";
import ImageHome3 from "images/homepage_03.jpg";
import ImageHome2 from "images/homepage_02.jpg";
import ImageHome1 from "images/homepage_01.jpg";

import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionStatistic from "containers/PageAbout/SectionStatistic";
import { getVRs } from "services/api";
const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Athens",
    taxonomy: "category",
    thumbnail:
      "https://orbito.s3.eu-central-1.amazonaws.com/images/top-destinations/_Athens.jpg",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Amsterdam",
    taxonomy: "category",
    count: "coming soon",
    thumbnail:
      "https://orbito.s3.eu-central-1.amazonaws.com/images/top-destinations/_Amterdam.jpg",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Barcelona",
    taxonomy: "category",
    count: "coming soon",
    thumbnail:
      "https://orbito.s3.eu-central-1.amazonaws.com/images/top-destinations/_Barcelona.jpg",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Berlin",
    taxonomy: "category",
    count: "coming soon",
    thumbnail:
      "https://orbito.s3.eu-central-1.amazonaws.com/images/top-destinations/_Berlin.jpg",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Rome",
    taxonomy: "category",
    count: "coming soon",
    thumbnail:
      "https://orbito.s3.eu-central-1.amazonaws.com/images/top-destinations/_Rome.jpg",
  },
  {
    id: "6",
    href: "/listing-stay",
    name: "Vienna",
    taxonomy: "category",
    count: "coming soon",
    thumbnail:
      "https://orbito.s3.eu-central-1.amazonaws.com/images/top-destinations/_Vienna.jpg",
  },
];

function PageHome() {
  const searchFormRef = useRef(null);

  const isSearchFormInView = useScrollPosition(searchFormRef);

  const dispatch = useDispatch();
  const location = useLocation();
  const [vrs, setVrs] = useState([]);

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(resetCart());
      dispatch(setHotels([]));
      // dispatch(resetSearch());
    }
  });
  useEffect(() => {
    console.log("VRS", vrs);
  }, [vrs]);

  useEffect(() => {
    window.isSearchFormInView = isSearchFormInView;
  }, [isSearchFormInView]);

  useEffect(() => {
    getVRs()
      .then((res) => {
        console.log("VRs", res);
        setVrs(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="nc-PageHome relative overflow-hidden -mt-9">
      <Seo
        name="Orbito Travel | Redefining Accessible Travel"
        description="Seamless Accessible Travel Planning: Orbito Travel | Your End-to-End Online Travel Partner for Travelers With Mobility Disabilities."
        title="Orbito Travel | Redefining Accessible Travel"
        type="page"
      />

      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28 mt-12">
        <div
          className="relative flex items-center justify-center  bg-center bg-cover bg-no-repeat bg-gray-100 dark:bg-neutral-900 rounded-xl"
          style={{
            backgroundImage: `url(${ImageHome3})`,
            height: 624,
            width: "100%",
          }}
        >
          <div ref={searchFormRef}>
            <SearchForm />
          </div>
        </div>

        {/* SECTION HERO */}
        {/* <SectionHero /> */}

        {/* SECTION */}
        {/* <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: HIW1imgDark,
              title: "Browse",
              desc: "No limit to your exploration!",
              subDesc:
                "Navigate through six categories of distinct services, designed to fuel your travel.",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "Choose",
              desc: "Design your travel!",
              subDesc:
                "Check our Virtual Tours to ensure a worry-free trip that caters to your unique needs.",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Book",
              desc: "All in one go!",
              subDesc:
                "Complete your entire trip online and step onto your adventure with confidence.",
            },
          ]}
        /> */}
        {vrs && vrs.length > 0 && <SectionVRs vrs={vrs} />}

        {/* <SectionOurFeatures /> */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
        {/* <SectionMagazine5 posts={DEMO_POSTS} /> */}
        {/* <BlogPage helmet={false} /> */}
        <SectionSliderCategories
          heading="The Venues"
          subHeading=""
          categoryCardType="card5new"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridAuthorBox boxCard="box2" />
        </div>

        <SectionStatistic />

        <SectionSliderNewCategories
          heading="Supported By"
          subHeading=""
          categoryCardType="card5"
          itemPerRow={5}
          sliderStyle="style2"
          uniqueClassName="ListingCarDetailPage"
        />
      </div>
    </div>
  );
}

export default PageHome;
