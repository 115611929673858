import React, { useEffect, useRef, useState } from "react";
import TravelFromInput from "./TravelFromInput";
import ArrivingToInput from "./ArrivingToInput";
import TravelDatesInput from "./TravelDatesInput";
import GuestsInput from "./GuestsInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch, useSelector } from "react-redux";
import { useCustomSearchParams } from "context/SearchParamsContext";
import { useNavigate } from "react-router-dom";
import { addTravelingTo } from "store/actions/search";
import ArrivingCities from "constants/ArrivingToCities";
import { has } from "lodash";
import Validation from "components/Validation/Validation";

const InputWrapper = ({
  className = "",
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return <div className={`h-5/6 ${className}`}>{children}</div>;
};

const SearchForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getStringParams } = useCustomSearchParams();

  const travelingTo = useSelector((state: any) => state.search.travelingTo);
  const travelingFrom = useSelector((state: any) => state.search.travelingFrom);
  const startDate = useSelector((state: any) => state.search.dates.startDate);
  const endDate = useSelector((state: any) => state.search.dates.endDate);
  const guestWheelchair = useSelector(
    (state: any) => state.search.guests.guestWheelchair
  );
  const guestsAdults = useSelector(
    (state: any) => state.search.guests.guestAdults
  );

  const [errors, setErrors] = useState<any>({});
  const [touched, setTouched] = useState<any>({});

  // const handleValidation = () => {
  //   let tempErrors: any = {};
  //   let isValid = true;

  //   if (!travelingFrom && localStorage.getItem("no_flight") !== "true") {
  //     tempErrors["travelingFrom"] = "This field is required";
  //     isValid = false;
  //   }

  //   if (!startDate) {
  //     tempErrors["startDate"] = "This field is required";
  //     isValid = false;
  //   }
  //   if (!endDate) {
  //     tempErrors["endDate"] = "This field is required";
  //     isValid = false;
  //   }
  //   console.log(endDate, startDate);
  //   setErrors(tempErrors);
  //   return isValid;
  // };

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setTouched({
      travelingFrom: true,
      startDate: true,
      endDate: true,
    });

    // if (handleValidation()) {
    // if (travelingTo === null) {
    //   dispatch(addTravelingTo(ArrivingCities[0]));
    // }
    // if (localStorage.getItem("no_flight") === "true") {
    navigate({
      pathname: `/where-to-stay`,
      // search: getStringParams(),
    });
    //   return;
    // } else {
    //   navigate({
    //     pathname: `/services`,
    //     search: getStringParams(),
    //   });
    // }
    // }
  };
  // useEffect(() => {
  //   localStorage.removeItem("no_flight");
  // }, []);

  // const handleBlur = (field: string) => {
  //   setTouched((prevTouched: any) => ({
  //     ...prevTouched,
  //     [field]: true,
  //   }));
  //   var tempErrors = { ...errors };
  //   if (field === "dates") {
  //     delete tempErrors["startDate"];
  //     delete tempErrors["endDate"];
  //   } else {
  //     delete tempErrors[field];
  //   }
  //   setErrors(tempErrors);
  // };

  return (
    <div
      className="  cursor-pointer"
      onClick={() =>
        navigate({
          pathname: `/where-to-stay`,
          // search: getStringParams(),
        })
      }
    >
      <form
        onSubmit={handleSubmit}
        className="group  h-[80px] relative mt-8 rounded-full shadow-xl dark:shadow-2xl bg-[#f47d20] text-white dark:bg-neutral-800 flex flex-row items-center justify-start gap-2"
        noValidate
      >
        {/* <InputWrapper className="ml-2 w-2/6">
          <TravelFromInput onBlur={() => handleBlur("travelingFrom")} />
          {errors.travelingFrom && (
            <Validation
              classNameCustom="relative -top-2"
              text={errors.travelingFrom}
            />
          )}
        </InputWrapper> */}
        {/* <InputWrapper className="w-2/6">
          <ArrivingToInput />
        </InputWrapper>
        <InputWrapper className="w-3/5 ">
          <TravelDatesInput
            onBlur={() => {
              handleBlur("dates");
            }}
          />
          <div className=" flex flex-row justify-start gap-x-5 w-full">
            {errors.startDate && (
              <Validation
                text={errors.startDate}
                classNameCustom="relative -top-2"
              />
            )}
            {errors.endDate && errors.startDate && (
              <Validation
                text={errors.endDate}
                classNameCustom="relative -top-2"
              />
            )}
            {!errors.startDate && errors.endDate && (
              <Validation
                text={errors.endDate}
                classNameCustom="relative left-1/2 -top-2"
              />
            )}
          </div>
        </InputWrapper> */}
        {/* <InputWrapper className="w-1/5">
          <GuestsInput />
        </InputWrapper> */}
        <span className="w-full text-2xl ml-3 text-center">
          {" "}
          Book your accommodation for the DIS Festival 2024 in Larnaca, Cyprus
        </span>
        <InputWrapper className="flex-none flex justify-center items-center">
          <button
            type="submit"
            className="h-14 md:h-16 w-full md:w-16 rounded-full bg-white text-black dark:bg-[#F47D20] hover:bg-[#2097F4] hover:dark:bg-[#2097F4] flex items-center justify-center focus:outline-none mr-3 "
          >
            <span className="mr-3 md:hidden">Search</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </InputWrapper>
      </form>
    </div>
  );
};

export default SearchForm;
