import Heading from "shared/Heading/Heading";
import NcImage from "shared/NcImage/NcImage";
import NcPlayIcon from "shared/NcPlayIcon/NcPlayIcon";
import NcPlayIcon2 from "shared/NcPlayIcon2/NcPlayIcon2";
import { FC, useState } from "react";
import Vr1Image from "images/OT_homepage_VT_hotel-room.jpg";
import Vr2Image from "images/OT_homepage_VT_elevator.jpg";
import Vr3Image from "images/OT_homepage_VT_public-restroom.jpg";
import Vr4Image from "images/OT_homepage_VT_bus.jpg";
import Vr5Image from "images/OT_homepage_VT_restaurant.jpg";

export interface VideoType {
  id: string;
  title: string;
  thumbnail: string;
}

export interface VRType {
  id: string;
  name: string;
  href: string;
  vrPreview: string;
}

export interface SectionVrProps {
  vrs?: VRType[];
  className?: string;
}

const SectionVRs: FC<SectionVrProps> = ({ vrs = [], className = "" }) => {
  const [isPlay, setIsPlay] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);

  const renderMainVideo = () => {
    const vr: VRType = vrs[currentVideo];
    return (
      <div
        className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px] will-change-transform"
        title={vr.name}
      >
        {isPlay ? (
          <iframe
            style={{
              transform: "translateY(-50px) translateX(-80px) scale(1.18)", // Shift up and slightly scale
              width: "100%",
              height: "100%",
              border: "none", // Remove border
            }}
            data-hj-allow-iframe
            src={vr.href}
            title={vr.name}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <>
            <div
              onClick={() => setIsPlay(true)}
              className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
            >
              <NcPlayIcon />
            </div>
            <NcImage
              containerClassName="absolute inset-0 "
              className="object-cover w-full h-full transform transition-transform group-hover:scale-105 duration-300 nc-will-change-transform"
              src={vr.vrPreview}
              title={vr.name}
              alt={vr.name}
            />
          </>
        )}
      </div>
    );
  };

  const renderSubVideo = (video: VRType, index: number) => {
    if (index === currentVideo) return null;
    return (
      <div
        className="group relative aspect-h-16 aspect-w-16 rounded-2xl cursor-pointer overflow-hidden sm:aspect-h-12 sm:rounded-3xl lg:aspect-h-9 will-change-transform"
        onClick={() => {
          setCurrentVideo(index);
          !isPlay && setIsPlay(true);
        }}
        title={video.name}
        key={String(index)}
      >
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <NcPlayIcon2 />
        </div>
        <NcImage
          containerClassName="absolute inset-0 w-full h-full"
          className="object-cover w-full h-full transform transition-transform group-hover:scale-110 duration-300 nc-will-change-transform"
          src={video.vrPreview}
          title={video.name}
          alt={video.name}
        />
      </div>
    );
  };

  return (
    <div className={`nc-SectionVideos ${className}`}>
      <div className="mb-10 md:mb-12">
        <h2 className="font-semibold text-4xl mt-5">Our Virtual Tours</h2>
        <span
          className="mt-2 md:mt-3 font-normal block 
        text-base sm:text-xl text-neutral-500 dark:text-neutral-400"
        >
          We are committed to providing an accessible and welcoming experience
          for all the participants. While we offer virtual tours for those with
          mobility disabilities, we encourage everyone to let us know how we can
          better support their participation to the Festival.
        </span>
      </div>
      <div className="flex flex-col relative sm:pr-4 sm:py-4 md:pr-6 md:py-6 xl:pr-14 xl:py-14 lg:flex-row">
        <div className="absolute -top-4 -bottom-4 -right-4 w-2/3 rounded-3xl bg-neutral-50  z-0 sm:rounded-[50px] md:top-0 md:bottom-0 md:right-0 xl:w-1/2 dark:bg-neutral-800 dark:bg-opacity-40"></div>
        <div className="flex-grow relative pb-2 sm:pb-4 lg:pb-0 lg:pr-5 xl:pr-6">
          {renderMainVideo()}
        </div>
        <div className="flex-shrink-0 grid gap-2 grid-cols-4 sm:gap-6 lg:grid-cols-1 lg:w-36 xl:w-40">
          {vrs.map(renderSubVideo)}
        </div>
      </div>
    </div>
  );
};

export default SectionVRs;
