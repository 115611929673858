import moment, { Moment } from "moment";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { addHowToGetThere, removeHowToGetThere } from "store/actions/cart";
import { euro } from "utils/currency";

import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { TextBlock } from "react-placeholder/lib/placeholders";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SearchType } from "store/reducers/search";
import AddToCartModal from "components/AddToCartModal/AddToCartModal";
import { eq } from "lodash";

export interface FlightCardProps {
  className?: string;
  data: any;
  airlines?: any;
  travelAirports: any;
  isOnCart: boolean;
  isLoading?: boolean;
}

const convertFormatDuration = (departureMinutes: number): string => {
  const hours = Math.floor(departureMinutes / 60);
  const minutes = departureMinutes % 60;
  return `${hours}h ${minutes}m`;
};

const FlightCard: FC<FlightCardProps> = ({
  className = "",
  data,
  airlines,
  travelAirports,
  isOnCart = false,
  isLoading,
}) => {
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  const search: SearchType = useSelector((state: any) => state.search);
  const totalGuests =
    search?.guests?.guestAdults + search?.guests?.guestWheelchair || 1;
  if (isLoading === undefined || isLoading === null) {
    isLoading = false;
  }

  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";

  const textRowsPlaceholder = (
    <div className="text-3-placeholder">
      <TextBlock color={placeholderColor} rows={5} />
    </div>
  );

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const routeDest = data.route.filter((route: any) => route.return === 0)[0];
  const routeRet = data.route.filter((route: any) => route.return === 1)[0];

  const price = data.conversion["EUR"];

  const [showAddToCartModal, setShowAddToCartModal] = useState(false);

  const getAirline = (airline: string) => {
    if (airlines.length) {
      const found = airlines.find(
        (airlineObject: any) => airline === airlineObject.code
      );
      return found;
    } else {
      return false;
    }
  };
  const flightClassDest = routeDest?.flightClass;
  const flightClassRet = routeRet?.flightClass;
  let routeDestStructure = {};
  if (routeDest) {
    routeDestStructure = {
      dTime: routeDest.local_departure.substring(11, 16),
      aTime: routeDest.local_arrival.substring(11, 16),
      dString: routeDest.local_departure.substring(11, 16),
      aString: routeDest.local_arrival.substring(11, 16),
      flyFrom: routeDest.flyFrom,
      flyTo: routeDest.flyTo,
      airlineCode: routeDest.airline,
      airlineName: getAirline(routeDest.airline)?.name || routeDest.airline,
      airlineLogo:
        getAirline(routeDest.airline)?.logo ||
        "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      duration: convertFormatDuration(data?.duration?.departure),
      flyFromAirport: travelAirports.depFrom,
      flyToAirport: travelAirports.arrTo,
      flightNumber: routeDest.flight_code,
      equipment: routeDest.equipment,
      flightClass: routeDest.flight_class,
      baggagePieces: routeDest.baggagePieces,
      cabinBaggagePieces: routeDest.cabinBaggagePieces,
    };
  } else {
    console.error("routeDest is undefined");
  }
  let routeRetStructure = {};
  if (routeRet) {
    routeRetStructure = {
      dTime: routeRet.local_departure.substring(11, 16),
      aTime: routeRet.local_arrival.substring(11, 16),
      dString: routeRet.local_departure.substring(11, 16),
      aString: routeRet.local_arrival.substring(11, 16),
      flyFrom: routeRet.flyFrom,
      flyTo: routeRet.flyTo,
      airlineCode: routeRet.airline,
      airlineName: getAirline(routeRet.airline)?.name || routeRet.airline,
      airlineLogo:
        getAirline(routeRet.airline)?.logo ||
        "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      duration: convertFormatDuration(data?.duration?.return),
      flyFromAirport: travelAirports.retDepFrom,
      flyToAirport: travelAirports.retArrTo,
      flightNumber: routeRet.flight_code,
      equipment: routeRet.equipment,
      flightClass: routeRet.flight_class,
      baggagePieces: routeRet.baggagePieces,
      cabinBaggagePieces: routeRet.cabinBaggagePieces,
    };
  } else {
    console.error("routeRet is undefined");
  }
  const addToCart = (routeDestStructure: any, routeRetStructure: any) => {
    if (isOnCart) {
      dispatch(removeHowToGetThere());
    } else {
      console.log("DATA", data);
      dispatch(
        addHowToGetThere({
          _id: data.id!,
          cart_type: "HOW_TO_GET_THERE",
          offerCode: data.id,
          searchGuid: data.search_guid,
          flightClass: flightClassDest,
          termsAndConditions: {
            cancelationPolicy: "Non Refundable",
            notes: `Cabin Luggage - 1, Checked Baggage - 1 plus 1 wheelchair`,
          },
          value: { price: data.price!, routeDestStructure, routeRetStructure },
        })
      );
      if (localStorage.getItem("disableNotification") !== "true") {
        setShowAddToCartModal(true);
      }
    }
  };
  const location = useLocation();

  const renderDetailTop = (route: any, title: string) => {
    return (
      <div>
        {title}
        <div className="flex flex-col md:flex-row ">
          <div className=" w-40 md:w-40 lg:w-40 flex-shrink-0 md:pt-7 ">
            <div className="dark:rounded-md w-40  flex flex-col items-center justify-center">
              <img
                draggable="false"
                src={route.airlineLogo}
                className="inset-0 w-[60px] h-[20px] object-contain rounded-md"
                alt=""
              />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {route.equipment}
              </span>
            </div>
          </div>
          <div className="flex my-5 md:my-0">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 flex flex-col justify-between text-sm">
              <div className="flex flex-col space-y-1">
                <span className="text-neutral-500 dark:text-neutral-400">
                  {route.dString}
                </span>
                <span className="font-semibold">
                  {route.flyFromAirport} ({route.flyFrom})
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className="text-neutral-500 dark:text-neutral-400">
                  {route.aString}
                </span>
                <span className="font-semibold">
                  {route.flyToAirport} ({route.flyTo})
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li>Trip duration: {route.duration}</li>
            <li>
              Travel class:{" "}
              {flightClassDest === "Econom" ? "Economy" : "Business"}
            </li>
            <li>Checked baggage: {route.baggagePieces} plus 1 wheelchair</li>
            <li>Cabin luggage: {route.cabinBaggagePieces}</li>
            <li>Flight Number: {route.flightNumber}</li>
            <li>Cancelation policy: non refundable</li>
          </ul>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {renderDetailTop(routeDestStructure, "Outbound")}
        <div className="border-t border-neutral-200 dark:border-neutral-700 mt-4 mb-4" />
        {renderDetailTop(routeRetStructure, "Inbound")}
      </div>
    );
  };

  const renderOutDetails = (route: any) => {
    return (
      <>
        {/* <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span> */}

        <div className="  flex  flex-col sm:flex-row sm:items-center sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-32 lg:w-32 flex-shrink-0">
            <div className="  dark:rounded-md w-16">
              <img
                draggable="false"
                src={route.airlineLogo}
                className="inset-0 w-[100px] h-[50px] object-contain rounded-md max-w-max"
                alt=""
              />
            </div>
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>{route.dTime}</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {route.flyFrom}
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>{route.aTime}</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {route.flyTo}
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">non-stop</span>
              <span className="mx-2">·</span>
              <span>{route.duration}</span>
              <span className="mx-2">·</span>
              <span>{route.flyFrom}</span>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">
              {route.dTime} - {route.aTime}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {route?.airlineName ?? route.airlineCode}
            </div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">
              {" "}
              {route.flyFrom} - {route.flyTo}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              Duration: {route.duration}
            </div>
          </div>

          {/* TYPE */}
          {location.pathname !== "/how-to-move-around" && (
            <div className="hidden lg:block flex-[2] whitespace-nowrap">
              <div className="font-medium text-lg">non-stop</div>
              {/* <div className="text-sm text-neutral-500 font-normal mt-0.5">
              2 hours 15 minutes BKK
            </div> */}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <ReactPlaceholder
        ready={!isLoading}
        customPlaceholder={textRowsPlaceholder}
        showLoadingAnimation
      >
        <div
          className={`nc-FlightCardgroup p-4  relative bg-white dark:bg-neutral-900 border border-neutral-100
 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow  ${className}`}
          data-nc-id="FlightCard"
        >
          {!isOpen && (
            <div className="absolute top-0 left-0 w-8 h-full -500 flex items-center justify-center flex-col">
              <span className="-rotate-90 text-white text-sm font-bold ">
                {flightClassDest === "Econom" ? "Economy" : "Business"}
              </span>
            </div>
          )}
          <AddToCartModal
            title={"Flight"}
            showModal={showAddToCartModal}
            onClose={() => setShowAddToCartModal(false)}
          />
          {data.totalRoutesEmissionsKgEconomy > 0 ? (
            <div className="absolute top-4 right-4 flex items-center">
              <span className="text-eco-friendly-green font-bold text-2xl">
                <i className="las la-leaf"></i>
              </span>
              <span className="ml-2 text-sm text-eco-friendly-green">
                CO<sub>2</sub>:{" "}
                {parseFloat(data.totalRoutesEmissionsKgEconomy).toFixed(1)}{" "}
                <sub>kg</sub>
              </span>
            </div>
          ) : (
            ""
          )}
          <div
            className={`relative  ${className} flex flex-row ml-6 items-center`}
            data-nc-id="FlightCard"
          >
            <div className=" basis-3/4">
              {renderOutDetails(routeDestStructure)}

              <div className=" relative border-t border-neutral-200 dark:border-neutral-700 mt-2 mb-2"></div>

              {renderOutDetails(routeRetStructure)}
            </div>

            <div className="basis-2/5 flex flex-col lg:flex-row items-center justify-between">
              <div className="whitespace-nowrap text-center pr-1 pl-4">
                <div>
                  <span className="text-xl font-semibold text-secondary-6000">
                    {euro.format(price)}
                  </span>
                </div>
                <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
                  for {totalGuests} {totalGuests > 1 ? "travelers" : "traveler"}{" "}
                  (total price)
                </div>
                <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
                  round-trip
                </div>
              </div>

              {location.pathname !== "/how-to-move-around" && (
                <div className="pr-1 pl-1">
                  <ButtonPrimary
                    onClick={() =>
                      addToCart(routeDestStructure, routeRetStructure)
                    }
                    fontSize="text-xs"
                    className={isOnCart ? "custom-green-button-cart" : ""}
                  >
                    {isOnCart ? "Added to cart" : "Add to cart"}
                  </ButtonPrimary>
                </div>
              )}

              <span
                className={`w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
                  isOpen ? "transform -rotate-180" : ""
                }`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <i className="text-xl las la-angle-down"></i>
              </span>
            </div>
          </div>

          {/* DETAIL */}
          {renderDetail()}
        </div>
      </ReactPlaceholder>
    </>
  );
};

export default FlightCard;
