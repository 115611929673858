import { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
// import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import MenuBar from "shared/MenuBar/MenuBar";
// import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { ShoppingCartIcon } from "@heroicons/react/24/solid";
// import { NAVIGATION_TABS } from "data/navigation";
// import { NavItemType } from "shared/Navigation/NavigationItem";

// import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import AvatarDropdown from "./AvatarDropdown";
import SignInDropdown from "./SignInDropdown";
import CurrencyDropdown from "./CurrencyDropdown";

import { useSelector } from "react-redux";
import { useCustomSearchParams } from "context/SearchParamsContext";
import { isMobile } from "react-device-detect";
import { CartType } from "store/reducers/cart";
import { SearchType } from "store/reducers/search";
import { date } from "zod";
import gu from "date-fns/esm/locale/gu/index.js";
import moment, { Moment } from "moment";
import SearchFormSmall from "components/HeroSearchForm/new/SearchFormSmall";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const authState = (state: any) => state.auth;
  const auth = useSelector(authState);
  const search: SearchType = useSelector((state: any) => state.search);
  const [showSearchFormInHeader, setShowSearchFormInHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowSearchFormInHeader(!window.isSearchFormInView);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isAuthenticated = auth.isAuthenticated;
  const {
    howToGetThere,
    whereToStay,
    whatToDo,
    whereToEat,
    howToMoveAround,
    whatsExtra,
  }: CartType = useSelector((state: any) => state.cart);

  const shoppingItemsCount: number =
    [
      howToGetThere,
      ...whereToStay,
      ...whatToDo,
      ...whereToEat,
      ...howToMoveAround,
      ...whatsExtra,
    ].filter((item) => item !== null).length || 0;

  const location = useLocation();
  const navigate = useNavigate();
  // const cartPage = location.pathname === '/checkout'
  const { getStringParams, searchParams } = useCustomSearchParams();
  const isDemo = searchParams.get("demo") === "true" ? true : false;

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const { travelingFrom } = search;

    if (!travelingFrom?.city || travelingFrom?.city === "") {
      localStorage.setItem("no_flight", "true");
    }
  }, []);

  const showReturnBtn: any = () => {
    if (isDemo) {
      return false;
    }
    if (location.pathname === "/") {
      return false;
    }
    if (
      location.pathname.startsWith("/checkout") ||
      location.pathname.startsWith("/blog")
      // location.pathname.startsWith("/how-to-get-there") ||
      // location.pathname.startsWith("/where-to-stay-detail") ||
      // location.pathname.startsWith("/what-to-do-detail") ||
      // location.pathname.startsWith("/where-to-eat-and-drink-detail") ||
      // location.pathname.startsWith("/how-to-move-around-detail") ||
      // location.pathname.startsWith("/what-is-extra-detail") ||
      // location.pathname.startsWith("/about") ||
      // location.pathname.startsWith("/accreditations") ||
      // location.pathname.startsWith("/privacy-policy") ||
      // location.pathname.startsWith("/terms-and-conditions") ||
      // location.pathname.startsWith("/terms-of-use") ||
      // location.pathname.startsWith("/contact") ||
      // location.pathname.startsWith("/faq")
    ) {
      return true;
    }
    return false;
  };

  const showNavigation: any = () => {
    return (
      !location.pathname.startsWith("/checkout") &&
      !window.location.search.includes("is_preview")
    );
  };

  const showSearchMaskInput = () => {
    if (isDemo) {
      return false;
    }
    if (
      location.pathname === "/" ||
      // location.pathname.startsWith("/how-to-get-there") ||
      // location.pathname.startsWith("/where-to-stay-detail") ||
      // location.pathname.startsWith("/what-to-do-detail") ||
      // location.pathname.startsWith("/where-to-eat-and-drink-detail") ||
      // location.pathname.startsWith("/how-to-move-around-detail") ||
      // location.pathname.startsWith("/what-is-extra-detail") ||
      location.pathname.startsWith("/pay-done") ||
      location.pathname.startsWith("/about") ||
      location.pathname.startsWith("/accreditations") ||
      location.pathname.startsWith("/privacy-policy") ||
      location.pathname.startsWith("/terms-and-conditions") ||
      location.pathname.startsWith("/terms-of-use") ||
      location.pathname.startsWith("/contact") ||
      location.pathname.startsWith("/faq") ||
      location.pathname.startsWith("/blog")
    ) {
      return false;
    }
    return true;
  };

  const showCart: any = () => {
    if (isDemo) {
      return false;
    }
    if (
      location.pathname === "/" ||
      location.pathname.startsWith("/checkout") ||
      location.pathname === "/pay-done" ||
      // location.pathname === "/cart" ||
      location.pathname.startsWith("/about") ||
      location.pathname.startsWith("/accreditations") ||
      location.pathname.startsWith("/privacy-policy") ||
      location.pathname.startsWith("/terms-and-conditions") ||
      location.pathname.startsWith("/terms-of-use") ||
      location.pathname.startsWith("/contact") ||
      location.pathname.startsWith("/faq") ||
      location.pathname.startsWith("/blog")
    ) {
      return false;
    }
    // if (
    //   location.pathname.startsWith("/services") ||
    //   location.pathname.startsWith("/how-to-get-there") ||
    //   location.pathname.startsWith("/where-to-stay-detail") ||
    //   location.pathname.startsWith("/what-to-do-detail") ||
    //   location.pathname.startsWith("/where-to-eat-and-drink-detail") ||
    //   location.pathname.startsWith("/how-to-move-around-detail") ||
    //   location.pathname.startsWith("/what-is-extra-detail")

    // ) {
    //   return false;
    // }
    return true;
  };
  const formatMoment = (date: Moment) => {
    date.format("D MMM ");
  };
  const renderButtonOpenHeroSearch = () => {
    const { travelingFrom, travelingTo, dates, guests } = search;
    const noFlight = localStorage.getItem("no_flight") === "true";

    const guestsCount = guests.guestAdults + guests.guestWheelchair || 0;

    return (
      <div
        className={`flex items-center md:mr-11 bg-neutral-50 h-[27px] 
        border border-neutral-200 dark:border-neutral-6000 
        rounded-full shadow hover:shadow-md transition-all text-[#676767] font-medium text-sm gap-2 text-nowrap`}
      >
        {!noFlight && <p className="pl-4 py-3 ">from {travelingFrom?.city}</p>}
        {!noFlight && (
          <span className="block h-5 w-[2px] bg-neutral-300 dark:bg-neutral-700"></span>
        )}
        <div className={`py-3 ${noFlight ? "pl-4" : ""}`}>
          to {travelingTo?.city}
        </div>
        <span className="block h-5 w-[2px]  bg-neutral-300 dark:bg-neutral-700"></span>
        <div className=" py-3 flex gap-1">
          <p>{dates.startDate?.format("D MMM") || ""} -</p>
          <p>{dates.endDate?.format("D MMM") || ""}</p>
        </div>
        <span className="block h-5 w-[2px] bg-neutral-300 dark:bg-neutral-700"></span>
        <div className="pr-4  py-3">
          {guestsCount} {guestsCount > 1 ? "travelers" : "traveler"}
        </div>
      </div>
    );
  };

  const classForReturnBtn = showReturnBtn() ? "justify-between" : "";
  return (
    <div>
      <div className={`nc-MainNav1 relative z-20 ${className} h-auto`}>
        <div className="px-3 lg:container py-3 lg:py-3 relative flex items-center">
          {isMobile ? (
            <div className="">
              <Logo />
            </div>
          ) : (
            <div className="invisible w-1/3">hidden</div>
          )}
          <div className="w-full mr-4 flex flex-row justify-start md:justify-center items-center "></div>
          <div className="w-1/12 md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none dark:text-neutral-100">
            <div className="xl:flex items-center space-x-0.5">
              {/* <CurrencyDropdown /> */}
              <div className="pr-1"></div>
              <SwitchDarkMode />
              <div className="pr-1"></div>
              {isAuthenticated && <AvatarDropdown />}
              {!isAuthenticated && <SignInDropdown />}
            </div>
          </div>
        </div>
      </div>

      <div className={`nc-MainNav1 relative z-10 -mt-3 pb-[10px]${className}`}>
        <div className="container flex items-center justify-between ">
          <div
            className={` ${
              showSearchFormInHeader && location.pathname === "/"
                ? "w-[400px]"
                : ""
            }`}
          >
            {!isMobile ? <Logo /> : null}
          </div>
          {showSearchFormInHeader && location.pathname === "/" && (
            <div className=" ml-6 w-full flex items-center justify-center">
              <SearchFormSmall />
            </div>
          )}
          <div
            className={`flex space-x-6  first-step ${
              showSearchFormInHeader && location.pathname === "/"
                ? "w-1/3"
                : "w-2/3"
            }`}
          >
            {showNavigation() && <Navigation isDemo={isDemo} />}
          </div>
          {showCart() && (
            <div className="relative flex">
              <NavLink
                to={{
                  pathname: "/cart",
                  search: getStringParams(),
                }}
                className={({ isActive, isPending }) =>
                  `text-center group flex flex-col  ${
                    isActive ? "isActive" : ""
                  }`
                }
              >
                <div
                  className="inline-flex self-center text-neutral-700 dark:text-neutral-300 
            py-2 px-4 rounded-full group-[.isActive]:bg-[#EF8733] hover:bg-[#D9D9D9]"
                >
                  {shoppingItemsCount > 0 ? (
                    <div
                      className={`absolute  font-bold right-1 top-0 px-3 rounded-full text-[#676767] group-[.isActive]:text-white text-sm`}
                    >
                      {shoppingItemsCount}
                    </div>
                  ) : null}
                  <i
                    className={`las la-cart-arrow-down text-4xl group-[.isActive]:text-white`}
                  ></i>
                </div>
                <p className="text-[#676767] group-[.isActive]:text-[#EF8733] group-[.isActive]:font-bold">
                  cart
                </p>
              </NavLink>
            </div>
          )}
          {showReturnBtn() && (
            <div className="pr-3">
              <ButtonPrimary onClick={goBack}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 mr-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                  />
                </svg>
                {location.pathname.startsWith("/checkout")
                  ? "back to the shopping cart"
                  : "back"}
              </ButtonPrimary>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
