/* eslint-disable no-lone-blocks */
import Modal from "components/Modal/Modal";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import Stars from "components/Stars/Stars";
import { useCustomSearchParams } from "context/SearchParamsContext";
import { NAVIGATION_TABS } from "data/navigation";
import moment from "moment";
import { Moment } from "moment";
import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import airlineLogo from "images/OT_checkout_plane_background.jpg";
import { removeWhereToStay } from "store/actions/cart";

import {
  CartType,
  HowToMoveAroundCartItem,
  WhatsExtraCartItem,
} from "store/reducers/cart";
import { Guests, TravelingFrom, TravelingTo } from "store/reducers/search";
import { euro } from "utils/currency";
import Seo from "components/Seo/Seo";
import { ca } from "date-fns/locale";
import Logo from "images/orbito/logos/orbito_travel_logo_icon.png";

type ShoppingCard = {
  _id: string;
  cart_type: string;
  title: string;
  name: string;
  subdescr?: ReactNode;
  date?: string | Moment;
  price: number | String;
  stars?: number;
  guests: number;
  cardImg: string;
  termsAndConditions: {
    cancelationPolicy: string;
    notes: string;
  };
};

interface ShoppingListPerDateType {
  date: string;
  items: ShoppingCard[]; // Replace 'any' with the actual type of your array items
}

const getDatesInRange = (startDate: Moment, endDate: Moment) => {
  let dateArray = [];
  let currentDate = moment(startDate);

  while (currentDate <= endDate) {
    dateArray.push(currentDate.format("l"));
    currentDate.add(1, "days");
  }
  return dateArray;
};

const isDate = (str: string) => {
  // Attempt to parse the string as a date
  const date = new Date(str);
  // Check if the parsed date is valid and not NaN
  return !isNaN(date.getTime());
};

const categorizedByCategory = (
  items: ShoppingCard[]
): ShoppingListPerDateType[] => {
  const categorizedArray: ShoppingListPerDateType[] = [];

  items.forEach((item) => {
    let date: string = "Extra Services";

    if (item.date) {
      // Check if 'date' is a Moment object
      date = moment.isMoment(item.date)
        ? item.date.format("l")
        : String(item.date);
    }
    // Handle special cases
    switch (date) {
      case "ALL_DAYS":
        // Add to all days
        categorizedArray.forEach((category) => category.items.push(item));
        break;
      case "FIRST_DAY":
        // Add to the first day
        if (categorizedArray.length > 0) {
          categorizedArray[0].items.push(item);
        }
        break;
      case "LAST_DAY":
        // Add to the last day
        if (categorizedArray.length > 0) {
          const lastCategory = categorizedArray[categorizedArray.length - 1];
          lastCategory.items.push(item);
        }
        break;
      default:
        // Check if an entry with the current date already exists in the categorizedArray
        const existingCategory = categorizedArray.find((category) => {
          if ((isDate(category.date), isDate(date))) {
            return moment(category.date).isSame(date);
          }
          // console.log(category.date,date,category.date === date)
          return category.date === date;
        });

        if (existingCategory) {
          // If the category already exists, push the current item to its 'items' array
          existingCategory.items.push(item);
        } else {
          // If the category does not exist, create a new category with the current date and the current item
          categorizedArray.push({
            date,
            items: [item],
          });
        }
    }
  });

  const dateArray = categorizedArray
    .filter((ca) => isDate(ca.date))
    .map((ca) => ({ ...ca, date: moment(ca.date).format("l") }));
  const extraServiceArray = categorizedArray.filter(
    (ca) => ca.date === "Extra Services"
  );

  console.log(dateArray);

  // Sort dates in ascending order, placing "No Date" last
  dateArray.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());

  return [...dateArray, ...extraServiceArray];
};

const handleSelectedOffers = (
  items: HowToMoveAroundCartItem[] | WhatsExtraCartItem[],
  title: string
): ShoppingCard[] => {
  const shoppingCards: ShoppingCard[] = [];
  for (const item of items) {
    const {
      _id,
      cardImg,
      cart_type,
      name,
      selectedOffers,
      type,
      termsAndConditions,
    } = item;
    for (const selectedOffer of selectedOffers) {
      const { price, type, date, quantity } = selectedOffer;

      const shoppingCard: ShoppingCard = {
        _id,
        cardImg,
        cart_type,
        name,
        price,
        title,
        date,
        guests: quantity,
        subdescr: (
          <>
            <div className="flex flex-col text-[14px] lowercase">
              <p className="flex flex-row items-center truncate -mb-2">
                <span className="mr-1">{item.type}</span>
              </p>
              <p className="flex flex-row items-center lowercase truncate">
                {" "}
                {type}
              </p>
            </div>
          </>
        ),
        termsAndConditions: termsAndConditions,
      };
      shoppingCards.push(shoppingCard);
    }
  }

  return shoppingCards;
};

const findCategoryIcon = (name: string) => {
  const category = NAVIGATION_TABS.find((nav) =>
    nav.name.toLowerCase().startsWith(name.toLowerCase())
  );
  if (category) {
    return `las ${category.icon} text-3xl`;
  }
  if (name === "WHATS EXTRA") return `las la-user-shield text-3xl`;
  return "";
};

type ShoppingCartProps = {};
const ShoppingCart2: FC<ShoppingCartProps> = () => {
  const {
    travelingFrom,
    travelingTo,
    dates: { endDate, startDate },
    guests,
  }: {
    travelingFrom: TravelingFrom;
    travelingTo: TravelingTo;
    dates: {
      startDate: Moment;
      endDate: Moment;
    };
    guests: Guests;
  } = useSelector((state: any) => state.search);
  const { getStringParams } = useCustomSearchParams();
  const dispatch = useDispatch();

  const {
    howToGetThere,
    whereToStay,
    whatToDo,
    whereToEat,
    howToMoveAround,
    whatsExtra,
  }: CartType = useSelector((state: any) => state.cart);
  const [shoppingListPerDateType, setShoppingListPerDateType] = useState<
    ShoppingListPerDateType[]
  >([]);
  const destinationSubTitle =
    travelingTo?.city + ", " + travelingTo?.country || "";
  const travelDates = getDatesInRange(startDate, endDate);
  const daysDiff = moment(endDate).diff(moment(startDate), "days");
  const totalGuests = guests.guestAdults + guests.guestWheelchair;
  const howToGetThereTotal = howToGetThere?.value.price ?? 0;
  const whereToStayTotal = whereToStay.reduce((total, stayItem) => {
    return total + stayItem.value.price * daysDiff;
  }, 0);
  const whatToDoTotal = whatToDo.reduce(
    (acc, current) => acc + current.value.price * totalGuests,
    0
  );
  const whereToEatTotal = whereToEat.reduce(
    (acc, current) => acc + current.value.price,
    0
  );
  const howToMoveAroundTotal = howToMoveAround
    .flatMap((htma) => htma.selectedOffers)
    .map((offer) => offer.price)
    .reduce((acc, current) => acc + current, 0);
  const whatsExtraTotal = whatsExtra
    .flatMap((we) => we.selectedOffers)
    .map((offer) => offer.price)
    .reduce((acc, current) => acc + current, 0);

  const sum =
    howToGetThereTotal +
    whereToStayTotal +
    whatToDoTotal +
    whereToEatTotal +
    howToMoveAroundTotal +
    whatsExtraTotal;

  useEffect(() => {
    let howToGetThereShoppingCards: ShoppingCard[] = [];
    let whereToStayShoppingCards: ShoppingCard[] = [];
    if (howToGetThere) {
      const {
        _id,
        cart_type,
        termsAndConditions,
        flightClass,
        value: { price, routeDestStructure, routeRetStructure },
      } = howToGetThere;
      if (routeDestStructure) {
        const { airlineName, dString, aString } = routeDestStructure;

        howToGetThereShoppingCards.push({
          _id,
          cart_type,
          cardImg: airlineLogo,
          title: "HOW TO GET THERE",
          name: airlineName,
          price: price / 2,
          date: startDate,
          subdescr: (
            <>
              <div className="flex flex-col text-[14px] ">
                <p className="flex flex-row items-center truncate -mb-2">
                  <span className="mr-1">
                    air-tickets |{" "}
                    {flightClass === "Econom" ? "Economy" : flightClass}
                  </span>
                </p>
                <p className="flex flex-row items-center truncate">
                  {" "}
                  departure: {dString} | arrival: {aString}
                </p>
              </div>
            </>
          ),
          guests: guests.guestAdults + guests.guestWheelchair,
          termsAndConditions: termsAndConditions,
        });
      }

      if (routeRetStructure) {
        const { airlineName, dString, aString } = routeRetStructure;
        howToGetThereShoppingCards.push({
          _id,
          cart_type,
          cardImg: airlineLogo,
          title: "HOW TO GET THERE",
          name: airlineName,
          price: price / 2,
          date: endDate,
          subdescr: (
            <>
              <div className="flex flex-col text-[14px] lowercase">
                <p className="flex flex-row items-center truncate -mb-2">
                  <span className="mr-1">air-tickets</span>
                </p>
                <p className="flex flex-row items-center truncate">
                  {" "}
                  departure: {dString} | arrival: {aString}
                </p>
              </div>
            </>
          ),
          guests: guests.guestAdults + guests.guestWheelchair,
          termsAndConditions: termsAndConditions,
        });
      }
    }
    if (whereToStay.length > 0) {
      whereToStay.forEach((stayItem) => {
        const {
          _id,
          cart_type,
          inputs: { cancelation, meals, guests },
          value,
        } = stayItem;
        console.log("VALUE", value);
        {
          travelDates.map((date, i) => {
            whereToStayShoppingCards.push({
              _id,
              cart_type,
              title: "WHERE TO STAY",
              name: value.name,
              price: i === travelDates.length - 1 ? "Check out" : value.price,
              stars: value.stars,
              date: date,
              subdescr: (
                <>
                  <div className="flex flex-col text-[14px] lowercase relative">
                    {value.dis_is_accessible && (
                      <img
                        alt="availability icon"
                        src={Logo}
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          width: 30,
                        }}
                      />
                    )}
                    {value.stars && (
                      <p className="flex flex-row items-center -mb-2">
                        <span className="mr-1">hotel</span>{" "}
                        <Stars stars={value.stars} />
                      </p>
                    )}

                    <p className="flex flex-row items-center truncate -mb-2">
                      {cancelation} | {meals}
                    </p>
                    <p className="flex flex-row items-center truncate">
                      {value.dis_single_selected
                        ? value.dis_single_count +
                          " single room" +
                          (value.dis_single_count && value.dis_single_count > 1
                            ? "s"
                            : "")
                        : null}{" "}
                      {value.dis_double_selected && value.dis_single_selected
                        ? "|"
                        : ""}{" "}
                      {value.dis_double_selected
                        ? value.dis_double_count +
                          " double room" +
                          (value.dis_double_count && value.dis_double_count > 1
                            ? "s"
                            : "")
                        : null}
                    </p>
                  </div>
                </>
              ),
              cardImg: value?.cardImgs[0]?.url || "",
              guests,
              termsAndConditions: {
                cancelationPolicy: value.dis_cancelationPolicy || "",
                notes: value.notes || "",
              },
            });
          });
        }
      });

      //   whereToStayShoppingCards.push({
      //     _id,
      //     cart_type,
      //     title: "WHERE TO STAY",
      //     name: value.name,
      //     price: value.price,
      //     stars: value.stars,
      //     date: endDate,
      //     subdescr: (
      //       <>
      //         <div className="flex flex-col text-[14px] lowercase">
      //           {value.stars && (
      //             <p className="flex flex-row items-center -mb-2">
      //               <span className="mr-1">hotel</span>{" "}
      //               <Stars stars={value.stars} />
      //             </p>
      //           )}
      //           <p className="flex flex-row items-center truncate">
      //             {cancelation} | {meals}
      //           </p>
      //         </div>
      //       </>
      //     ),
      //     cardImg: value?.cardImgs[0]?.url || "",
      //     guests,
      //     termsAndConditions: {
      //       cancelationPolicy: value.cancelationPolicy || "",
      //       notes: value.notes || "",
      //     },
      //   });
    }

    const whatToDoShoppingCards: ShoppingCard[] = whatToDo.map((htma) => {
      const timeDurationToDate = moment(htma.inputs.timeslot, "HH:mm").add(
        htma.value.duration,
        "minutes"
      );
      return {
        cart_type: htma.cart_type,
        _id: htma._id,
        title: "WHAT TO DO",
        name: htma.value.name,
        date: htma.inputs.date || "",
        price: htma.value.price,
        cardImg: htma.value?.cardImgs[0]?.url || "",
        guests: htma.inputs.guests,
        subdescr: (
          <>
            <div className="flex flex-col text-[14px] lowercase">
              <p className="flex flex-row items-center truncate -mb-2">
                <span className="mr-1">{htma.value.type}</span>
              </p>
              <p className="flex flex-row items-center truncate">
                {" "}
                from: {htma.inputs.timeslot} | until:{" "}
                {timeDurationToDate.format("HH:mm")}
              </p>
            </div>
          </>
        ),
        termsAndConditions: {
          cancelationPolicy: htma.value.cancelationPolicy || "",
          notes: htma.value.notes || "",
        },
      };
    });
    const whereToEatShoppingCards: ShoppingCard[] = whereToEat.map((htma) => {
      return {
        cart_type: htma.cart_type,
        _id: htma._id,
        title: "WHERE TO EAT",
        name: htma.value.name,
        date: htma.inputs.date,
        price: htma.value.price,
        subdescr: (
          <>
            <div className="flex flex-col text-[14px] lowercase">
              <p className="flex flex-row items-center truncate -mb-2">
                <span className="mr-1">{htma.value.type}</span>
              </p>
              <p className="flex flex-row items-center truncate">
                {htma.inputs.timeslot} | {htma.inputs.seating}
              </p>
            </div>
          </>
        ),
        cardImg: htma.value?.cardImgs[0]?.url || "",
        guests: htma.inputs.guests,
        termsAndConditions: {
          cancelationPolicy: htma.value.cancelationPolicy || "",
          notes: htma.value.notes || "",
        },
      };
    });
    const howToMoveAroundShoppingCards: ShoppingCard[] = handleSelectedOffers(
      howToMoveAround,
      "HOW TO MOVE"
    );
    const whatsExtraShoppingCards: ShoppingCard[] = handleSelectedOffers(
      whatsExtra,
      "WHATS EXTRA"
    );
    const array: ShoppingCard[] = [
      ...howToGetThereShoppingCards,
      ...whereToStayShoppingCards,
      ...whatToDoShoppingCards,
      ...whereToEatShoppingCards,
      ...howToMoveAroundShoppingCards,
      ...whatsExtraShoppingCards,
    ];
    const categorizedItems = categorizedByCategory(array);
    setShoppingListPerDateType(categorizedItems);
  }, []);

  const formatMoment = (date: string) => {
    const formattedDate = moment(date, "M/D/YYYY").format("D MMM YYYY");
    return formattedDate !== "Invalid date" ? formattedDate : date;
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        {/* <h2 className="text-3xl lg:text-4xl font-semibold">Shopping Cart</h2> */}
        {/* <div className="border-b border-neutral-200 dark:border-neutral-700"></div> */}
        <div className="mt-6 flex flex-col space-y-8">
          {shoppingListPerDateType.length > 0 ? (
            <>
              {shoppingListPerDateType.map((listPerDate, index) => (
                <div key={index} className="listingSection__wrap">
                  <div className="rounded-3xl text-[#F47D20] font-semibold text-lg">
                    {formatMoment(listPerDate.date)}
                  </div>
                  <div className="grid grid-cols-2 gap-10 mx-2 md:mx-4">
                    {listPerDate.items.length > 0
                      ? listPerDate.items.map((shoppingCard) => (
                          <ShoppingCartItem
                            key={shoppingCard._id}
                            shoppingCard={shoppingCard}
                            onDeleteCard={handleDeleteCard}
                          />
                        ))
                      : null}
                  </div>
                </div>
              ))}
            </>
          ) : (
            "No Items Selected"
          )}
        </div>
      </div>
    );
  };

  const handleDeleteCard = (cardId: string) => {
    setShoppingListPerDateType(
      (prevList) =>
        prevList
          .map((listPerDate) => ({
            ...listPerDate,
            items: listPerDate.items.filter((item) => item._id !== cardId),
          }))
          .filter((listPerDate) => listPerDate.items.length > 0) // Filter out empty dates
    );
    dispatch(removeWhereToStay(cardId));
    window.location.reload();
  };
  const renderSidebar = () => {
    return (
      <>
        {sum && sum > 0 ? (
          <div className="w-full min-h-min ">
            <div className="sticky listingSection__wrap rounded-xl flex flex-col justify-center space-y-4">
              {renderCategoryTotalSection({
                type: howToGetThere,
                title: "How to get there",
                total: howToGetThereTotal,
              })}
              {renderCategoryTotalSection({
                type: whereToStay,
                title: "Where to stay",
                total: whereToStayTotal,
              })}
              {renderCategoryTotalSection({
                type: whatToDo,
                title: "What to do",
                total: whatToDoTotal,
              })}
              {renderCategoryTotalSection({
                type: whereToEat,
                title: "Where to eat",
                total: whereToEatTotal,
              })}
              {renderCategoryTotalSection({
                type: howToMoveAround,
                title: "How to move around",
                total: howToMoveAroundTotal,
              })}
              {renderCategoryTotalSection({
                type: whatsExtra,
                title: "What's extra",
                total: whatsExtraTotal,
              })}
              <div>
                <div className="flex flex-row justify-between font-semibold text-lg">
                  <p className="">Total trip cost</p>
                  <p>{euro.format(sum)}</p>
                </div>
              </div>
              {sum && sum > 0 ? (
                <div>
                  <Link
                    to={{
                      pathname: "/checkout",
                      search: getStringParams(),
                    }}
                  >
                    <div
                      className="bg-[#F47D20] hover:bg-[#2097F4] text-neutral-50 w-full  rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 
                  text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"
                    >
                      Proceed to pay {euro.format(sum)}
                    </div>
                  </Link>
                  {/* {whereToStay &&
                    whereToStay.cash_tax &&
                    whereToStay.cash_tax.value !== 0 && (
                      <div className="mt-1">
                        <span className="text-sm">
                          Please note that the amount of{" "}
                          {whereToStay?.cash_tax?.currency === "EUR"
                            ? "€"
                            : "$"}{" "}
                          {(whereToStay?.cash_tax?.value).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}{" "}
                          for the {whereToStay?.cash_tax?.title} should be paid
                          on-site at the hotel by credit/debit card or cash
                        </span>
                      </div>
                    )} */}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderCategoryTotalSection = ({
    type,
    title,
    total,
  }: {
    type: any;
    title: string;
    total: number;
  }) => {
    if (!type || !title || !total) return null;

    return (
      <div>
        <div className="flex flex-row justify-between">
          <p>{title}</p>
          <p>{euro.format(total)}</p>
        </div>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700 mt-1"></div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage container`} data-nc-id="CheckOutPage">
      <Seo
        name="Shopping Cart | Orbito Travel"
        description="Review and manage your selections with Orbito Travel's shopping cart. Explore our convenient platform for booking accessible travel services and accommodations. Start organizing your dream trip today!"
        title="Shopping Cart | Orbito Travel"
        type="page"
      />
      {/* <SectionHeroArchivePage
        currentPage="Flights"
        currentTab="Flights"
        title="Review your trip to"
        subTitle={destinationSubTitle}
        rightImage={
          "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/02.png"
        }
        className="pt-10 lg:pt-16 z-0"
      /> */}
      <main className=" mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row relative">
        {sum === 0 ? (
          <div className="text-xl font-semibold">The cart is empty</div>
        ) : (
          <>
            <div className="w-2/3 lg:pr-10 ">{renderMain()}</div>
            <div className="block flex-grow w-1/3">
              <div className="sticky top-52 ">{renderSidebar()}</div>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

type ShoppingCartItemProps = {
  shoppingCard: ShoppingCard;
  onDeleteCard?: (cardId: string) => void;
};

const ShoppingCartItem: FC<ShoppingCartItemProps> = ({
  shoppingCard: {
    _id,
    cardImg,
    cart_type,
    name,
    price,
    title,
    date,
    subdescr = null,
    stars,
    guests,
    termsAndConditions,
  },
  onDeleteCard,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openDeleteConfirmModal = () => {
    setIsDeleteConfirmModalOpen(true);
  };

  const closeDeleteConfirmModal = () => {
    setIsDeleteConfirmModalOpen(false);
  };

  const handleDelete = () => {
    if (!onDeleteCard) return;
    onDeleteCard(_id);
    closeDeleteConfirmModal();
  };

  return (
    <div
      className={`flex flex-col w-full sm:rounded-2xl border-b sm:border-t sm:border-l 
sm:border-r border-neutral-200 dark:border-neutral-700  
sm:space-y-2 pb-10 px-0 sm:p-4 bg-no-repeat bg-cover text-neutral-700 dark:text-neutral-600`}
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.90), rgba(255, 255, 255, 0.90)),
    url('${cardImg}')`,
      }}
    >
      <div className={`flex flex-row justify-between items-center`}>
        <div>
          <span>
            <i className={findCategoryIcon(title)}></i>
          </span>
        </div>
        <div className="lowercase italic flex-1 ml-1 ">
          <p className="truncate text-[12px]">{title}</p>
        </div>
        <div className="text-[16px]">
          <p>
            {guests}
            <span className="ml-2">{guests > 1 ? "adults" : "adult"}</span>
          </p>
        </div>
        <button
          onClick={openDeleteConfirmModal}
          className="text-black-500 text-xl relative -right-2 -top-4"
        >
          &times;
        </button>
      </div>
      <div className="flex flex-1 flex-col ">
        <h2 className="font-bold text-[18px] truncate ">{name}</h2>
        {subdescr && subdescr}
      </div>
      <div className="w-14 border-b border-neutral-400 dark:border-neutral-800"></div>
      <div>
        <div className="flex flex-row justify-between">
          <p className="text-base font-semibold text-[18px]">
            {typeof price === "number" ? euro.format(price) : price}
          </p>
          <div>
            <button
              onClick={openModal}
              className="text-[12px] underline hover:text-neutral-900"
            >
              terms & conditions
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        content={
          <div className="inline-block h-100 w-136 z-100">
            <div
              className="inline-flex  flex-col w-full text-left align-middle transition-all transform rounded-2xl
               bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-[50vh]"
            >
              <div className="relative flex-shrink-0 px-4 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h3
                  className="text-lg font-medium leading-6 light:text-gray-900 dark:text-orange-600"
                  id="headlessui-dialog-title-70"
                >
                  Terms and Conditions
                </h3>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={() => setIsModalOpen(false)} />
                </span>
              </div>
              <div className="px-6 py-6 h-100 overflow-scroll text-neutral-700 dark:text-neutral-200">
                <div className="listingSection__wrap">
                  {/* HEADING */}
                  <h2 className="text-2xl font-semibold">
                    Terms and Conditions
                  </h2>
                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

                  {/* CONTENT */}
                  <div>
                    <h4 className="text-lg font-semibold">
                      Cancellation policy
                    </h4>
                    <div className="block mt-3 text-neutral-500 dark:text-neutral-400 text-wrap ">
                      <div
                        style={{
                          wordWrap: "break-word",
                        }}
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: termsAndConditions.cancelationPolicy,
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

                  <div>
                    <h4 className="text-lg font-semibold">Notes</h4>
                    <div className="prose sm:prose">
                      <div className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-wrap">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: termsAndConditions.notes,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Modal
        isOpen={isDeleteConfirmModalOpen}
        closeModal={closeDeleteConfirmModal}
        content={
          <div className="inline-block h-100 w-136 z-100">
            <div
              className="inline-flex  flex-col w-full text-left align-middle transition-all transform rounded-2xl
               bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-[30vh]"
            >
              <div className="relative flex-shrink-0 px-4 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h3
                  className="text-lg font-medium leading-6 light:text-gray-900 dark:text-orange-600"
                  id="headlessui-dialog-title-70"
                >
                  Confirm Deletion
                </h3>
                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={closeDeleteConfirmModal} />
                </span>
              </div>
              <div className="px-6 py-6 h-100 text-center text-neutral-700 dark:text-neutral-200">
                <p>
                  Are you sure you want to remove this item from the shopping
                  cart?
                </p>
                <div className="flex justify-center space-x-4 mt-4">
                  <ButtonPrimary onClick={handleDelete}>YES</ButtonPrimary>
                  <ButtonPrimary onClick={closeDeleteConfirmModal}>
                    NO
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ShoppingCart2;
